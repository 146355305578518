import { Box } from '@mui/material';
import { useListing } from 'api/graphql/hooks/useListing';
import { EVChip, EVChipProps } from 'components/general/Chips/EVChip/EVChip';
import { TooltipButton } from 'components/general/Chips/LeadChip/TooltipButton';
import { EVAvatar } from 'components/general/EVAvatar/EVAvatar';
import { PopperEntity, PopperEntityHeader } from 'components/general/Popper/Entity';
import { Popper } from 'components/general/Popper/Popper';
import { Price } from 'components/general/Price';
import ExternalLinkIcon from 'components/icons/link_external.svg?react';
import { GoEVIdLink } from 'page-components/contact/EVIdWithLink/GoEVIdLink';
import React from 'react';
import { useTranslation } from 'util/i18next';
import { addressToSingleLineString, addressToStreetString } from 'util/summaries/addressSummary';

type PropertyTooltipChipProps = {
  chipProps?: EVChipProps;
  handleDelete?: () => void;
  propertyId: string;
};

export const PropertyTooltipChip: React.FC<PropertyTooltipChipProps> = ({
  chipProps,
  handleDelete,
  propertyId: utag,
}) => {
  const { t } = useTranslation(['enums']);

  const { listing, isLoading } = useListing({ utag }, { enabled: !!utag });
  const { address, previewImage, askingPrice, monthlyTotalRent } = listing || {};
  const goPath = `${listing?.type === 'SALE' ? 'sales' : 'lettings'}/${utag}`;

  return (
    <Box sx={{ display: 'inline-flex' }}>
      <Popper
        isLoading={isLoading}
        placement={'top-start'}
        anchor={
          <EVChip
            onDelete={handleDelete}
            label={addressToStreetString(address, listing?.fileAs)}
            avatar={previewImage ? <EVAvatar size="s" pictureSrc={previewImage} /> : undefined}
            {...chipProps}
          />
        }
      >
        <PopperEntity
          header={
            <PopperEntityHeader
              label={<GoEVIdLink goPath={goPath} label={utag} />}
              avatar={previewImage ? <EVAvatar isSquare size="l" pictureSrc={previewImage} /> : undefined}
              subLabel={addressToSingleLineString(t)(address)}
            />
          }
          body={
            <Price price={listing?.type === 'SALE' ? askingPrice : monthlyTotalRent} currency={listing?.currency} />
          }
          footer={
            <GoEVIdLink goPath={goPath} target="_blank">
              <TooltipButton fullWidth icon={<ExternalLinkIcon />}>
                {t('menu.openInNewTab')}
              </TooltipButton>
            </GoEVIdLink>
          }
        />
      </Popper>
    </Box>
  );
};
