import { useLocale } from '@ev/eva-container-api';
import { CurrencyEnum, Scalars } from 'api/graphql/generated/graphql';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useCallback, useMemo } from 'react';
import { EMPTY_DATA_STRING, replaceUndefinedOption } from 'util/emptyDataUtils';

export type NumberStyle = 'decimal' | 'currency' | 'percent' | 'unit';
export type MeasurementSystem = 'METRIC' | 'IMPERIAL';

const AREA_SYMBOL = {
  IMPERIAL: 'ft²',
  METRIC: '\u33A1', // m²
} satisfies Record<MeasurementSystem, string>;

export interface INumberOptions {
  style?: NumberStyle;
  currency?: CurrencyEnum | Scalars['PropertyEngineCurrency'] | null;
  emptyDataString?: string;
  locale?: string;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
}

export interface INumericRange {
  from?: number;
  to?: number;
  options?: INumberOptions;
}

export interface IFormattedNumber {
  number?: number;
  options?: INumberOptions;
}

function getNumericFormatOptions(
  { style, currency, minimumFractionDigits, maximumFractionDigits }: INumberOptions,
  defaultCurrency: Maybe<CurrencyEnum | Scalars['PropertyEngineCurrency']>,
): Intl.NumberFormatOptions {
  return {
    style,
    ...(style === 'currency'
      ? {
          currency:
            replaceUndefinedOption(currency || undefined) ||
            replaceUndefinedOption(defaultCurrency || undefined) ||
            'EUR',
        }
      : {}),
    minimumFractionDigits: minimumFractionDigits ?? 0,
    maximumFractionDigits: maximumFractionDigits ?? 2,
  };
}

export function useFormatNumber() {
  const locale = useLocale();
  const { shopCurrency } = useActiveShop();

  // Extracts locale-specific number separators for proper formatting in NumberInput (react-number-format).
  const localeFormattingSettings = useMemo(() => {
    // Sample number used to determine locale-specific separators
    const SAMPLE_NUMBER = 1000.1;
    const formatter = new Intl.NumberFormat(locale);
    const parts = formatter.formatToParts(SAMPLE_NUMBER);

    const getPartValue = (type: Intl.NumberFormatPartTypes) => parts.find((part) => part.type === type)?.value;

    return {
      thousandSeparator: getPartValue('group') ?? false,
      decimalSeparator: getPartValue('decimal'),
    };
  }, [locale]);

  const formatNumber = useCallback(
    (number: Maybe<number>, options: INumberOptions = {}): string => {
      if (number === undefined || number === null) {
        return options?.emptyDataString ?? EMPTY_DATA_STRING;
      }

      const numericFormat = new Intl.NumberFormat(locale, getNumericFormatOptions(options, shopCurrency));
      if (options.style === 'currency') {
        return numericFormat
          .format(number)
          .replace(/^(\D+)/, '$1 ')
          .replace(/\s+/, ' ');
      }
      return numericFormat.format(number);
    },
    [locale, shopCurrency],
  );

  const formatRange = (from: Maybe<number>, to: Maybe<number>, options: INumberOptions = {}): string => {
    const formattedFrom = formatNumber(from, options);
    const formattedTo = formatNumber(to, options);

    if (from && to) {
      return `${formattedFrom} - ${formattedTo}`;
    }
    if (from) {
      return `> ${formattedFrom}`;
    }
    if (to) {
      return `< ${formattedTo}`;
    }
    return options?.emptyDataString ?? EMPTY_DATA_STRING;
  };

  const formatPrice = (
    number: Maybe<number>,
    options: INumberOptions & { currency: CurrencyEnum | Scalars['PropertyEngineCurrency'] | undefined | null },
  ) => formatNumber(number, { ...options, style: 'currency' });

  const formatPriceRange = (
    from: Maybe<number>,
    to: Maybe<number>,
    options: INumberOptions & { currency: CurrencyEnum | Scalars['PropertyEngineCurrency'] | undefined | null },
  ) => formatRange(from, to, { ...options, style: 'currency' });

  const formatArea = (number: Maybe<number>, options: INumberOptions & { unitSystem: MeasurementSystem }) => {
    if (number === undefined || number === null) {
      return options.emptyDataString ?? EMPTY_DATA_STRING;
    }

    return `${formatNumber(number, options)} ${AREA_SYMBOL[options.unitSystem]}`;
  };

  const formatAreaRange = (
    from: Maybe<number>,
    to: Maybe<number>,
    options: INumberOptions & { unitSystem: MeasurementSystem },
  ) => {
    if (from && to) {
      return `${formatArea(from, options)} - ${formatArea(to, options)}`;
    }
    if (from) {
      return `> ${formatArea(from, options)}`;
    }
    if (to) {
      return `< ${formatArea(to, options)}`;
    }
    return options.emptyDataString ?? EMPTY_DATA_STRING;
  };

  const formatPercent = (number: Maybe<number>, options: INumberOptions = {}, isPercentage = true) => {
    //Format number will multiply percent values by 100, not needed if this is already a percentage
    if (number && isPercentage) {
      number = number / 100;
    }
    return formatNumber(number, { ...options, style: 'percent' });
  };

  return {
    formatRange,
    formatNumber,
    formatPrice,
    formatPriceRange,
    formatArea,
    formatAreaRange,
    formatPercent,
    localeFormattingSettings,
  };
}

export function useCurrencySymbol(currency: CurrencyEnum | Scalars['PropertyEngineCurrency']) {
  const locale = useLocale();
  return useMemo(() => {
    return (0)
      .toLocaleString(locale, {
        style: 'currency',
        currency: replaceUndefinedOption(currency) || 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, '')
      .trim();
  }, [currency, locale]);
}

export function useAreaUnitSymbol(unitSystem: MeasurementSystem) {
  return AREA_SYMBOL[unitSystem];
}
