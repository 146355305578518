import { AssetConditionEnum, BuyerToRentSpecification, UnitSystemEnum } from 'api/graphql/generated/graphql';
import { assetConditionTranslation } from 'const/enumTranslations';
import { useEnumTranslation, useTranslation } from 'util/i18next';
import { titleWithValue } from 'util/stringUtils';
import { useFormatNumber } from 'util/useFormatNumber';

export type BuyerToRentAreaRanges = Pick<
  BuyerToRentSpecification,
  | 'totalSurfaceMax'
  | 'totalSurfaceMin'
  | 'livingAreaMin'
  | 'livingAreaMax'
  | 'plotSurfaceMin'
  | 'plotSurfaceMax'
  | 'unitSystem'
>;
export type RentRanges = Pick<
  BuyerToRentSpecification,
  'monthlyNetRentMin' | 'monthlyNetRentMax' | 'monthlyTotalRentMin' | 'monthlyTotalRentMax' | 'currency'
>;
export type RoomRanges = Pick<BuyerToRentSpecification, 'roomsMin' | 'roomsMax' | 'bedroomsMin' | 'bedroomsMax'>;

export function collapseValues(items: Record<string, string | undefined>, options = { isTitleAtEnd: false }) {
  const { isTitleAtEnd } = options;
  if (!items) {
    return;
  }
  return Object.entries(items)
    .map(([key, value]) => titleWithValue(key, value, { isTitleAtEnd }))
    .filter(Boolean)
    .join(', ');
}

export function useLeadDetailUtils() {
  const { t } = useTranslation(['lead']);
  const { translateEnum } = useEnumTranslation();
  const { formatArea, formatNumber, formatAreaRange, formatPriceRange, formatRange } = useFormatNumber();

  function getRentRanges(rentRanges?: RentRanges | null) {
    if (!rentRanges) {
      return;
    }

    return {
      [t('lead:propertyDetails.monthlyNetRent')]: formatPriceRange(
        rentRanges.monthlyNetRentMin,
        rentRanges.monthlyNetRentMax,
        {
          emptyDataString: '',
          currency: rentRanges.currency,
        },
      ),
      [t('lead:propertyDetails.monthlyTotalRent')]: formatPriceRange(
        rentRanges.monthlyTotalRentMin,
        rentRanges.monthlyTotalRentMax,
        {
          emptyDataString: '',
          currency: rentRanges.currency,
        },
      ),
    };
  }

  function getAreaRangeValues(buyerToRentAreaRanges?: BuyerToRentAreaRanges | null) {
    if (!buyerToRentAreaRanges) {
      return;
    }

    return {
      [t('lead:propertyDetails.totalSurface')]: formatAreaRange(
        buyerToRentAreaRanges.totalSurfaceMin,
        buyerToRentAreaRanges.totalSurfaceMax,
        {
          emptyDataString: '',
          unitSystem: buyerToRentAreaRanges.unitSystem,
        },
      ),
      [t('lead:propertyDetails.livingArea')]: formatAreaRange(
        buyerToRentAreaRanges.livingAreaMin,
        buyerToRentAreaRanges.livingAreaMax,
        {
          emptyDataString: '',
          unitSystem: buyerToRentAreaRanges.unitSystem,
        },
      ),
      [t('lead:propertyDetails.plotSurface')]: formatAreaRange(
        buyerToRentAreaRanges.plotSurfaceMin,
        buyerToRentAreaRanges.plotSurfaceMax,
        {
          emptyDataString: '',
          unitSystem: buyerToRentAreaRanges.unitSystem,
        },
      ),
    };
  }

  function getAreaValues({
    livingArea,
    plotSurface,
    unitSystem,
  }: {
    livingArea?: number | null;
    plotSurface?: number | null;
    unitSystem: UnitSystemEnum | null | undefined;
  }) {
    return {
      [t('lead:leadSummary.propertyDetails.livingArea')]: unitSystem
        ? formatArea(livingArea, {
            emptyDataString: '',
            unitSystem,
          })
        : '',
      [t('lead:leadSummary.propertyDetails.plotSurface')]: unitSystem
        ? formatArea(plotSurface, {
            emptyDataString: '',
            unitSystem,
          })
        : '',
    };
  }

  function collapseAreaValues({
    livingArea,
    plotSurface,
    unitSystem,
  }: {
    livingArea?: number | null;
    plotSurface?: number | null;
    unitSystem: UnitSystemEnum | null | undefined;
  }) {
    return collapseValues(getAreaValues({ livingArea, plotSurface, unitSystem }));
  }

  function collapseRoomNumbers({
    rooms,
    bedrooms,
    bathrooms,
  }: {
    rooms?: number | null;
    bedrooms?: number | null;
    bathrooms?: number | null;
  }) {
    return collapseRoomStrings({
      rooms: formatNumber(rooms, { emptyDataString: '' }),
      bedrooms: formatNumber(bedrooms, { emptyDataString: '' }),
      bathrooms: formatNumber(bathrooms, { emptyDataString: '' }),
    });
  }

  function collapseRoomStrings({
    rooms,
    bedrooms,
    bathrooms,
  }: {
    rooms?: string;
    bedrooms?: string;
    bathrooms?: string;
  }) {
    return collapseValues(
      {
        [t('lead:propertyEvaluateForm.details.rooms')]: rooms,
        [t('lead:propertyEvaluateForm.details.bedrooms')]: bedrooms,
        [t('lead:propertyEvaluateForm.details.bathrooms')]: bathrooms,
      },
      { isTitleAtEnd: true },
    );
  }

  function getRoomRanges(roomRanges: RoomRanges) {
    return {
      rooms: formatRange(roomRanges.roomsMin, roomRanges.roomsMax, { emptyDataString: '' }),
      bedrooms: formatRange(roomRanges.bedroomsMin, roomRanges.bedroomsMax, { emptyDataString: '' }),
    };
  }

  function getFurtherValues({
    floor,
    totalFloors,
    constructionYear,
    condition,
  }: {
    floor?: number | null;
    totalFloors?: number | null;
    constructionYear?: number | null;
    condition?: string | null;
  }): Record<string, string | undefined> {
    return {
      [t('lead:propertyEvaluateForm.details.floor')]: formatNumber(floor, {
        emptyDataString: '',
      }),
      [t('lead:propertyEvaluateForm.details.totalFloors')]: formatNumber(totalFloors, {
        emptyDataString: '',
      }),
      [t('lead:propertyEvaluateForm.details.yearOfConstruction')]: constructionYear
        ? String(constructionYear)
        : undefined,
      [t('lead:propertyEvaluateForm.details.condition')]:
        translateEnum(assetConditionTranslation, condition as AssetConditionEnum) ?? '',
    };
  }

  function collapseFurtherValues({
    floor,
    totalFloors,
    constructionYear,
    condition,
  }: {
    floor?: number | null;
    totalFloors?: number | null;
    constructionYear?: number | null;
    condition?: string | null;
  }) {
    return collapseValues(getFurtherValues({ floor, totalFloors, constructionYear, condition }));
  }

  function collapseFeatureValues({
    balcony,
    garden,
    parking,
    swimmingPool,
    guestToilet,
    elevator,
    garage,
    cdfs,
  }: {
    balcony?: boolean | null;
    garden?: boolean | null;
    parking?: boolean | null;
    swimmingPool?: boolean | null;
    guestToilet?: boolean | null;
    elevator?: boolean | null;
    garage?: boolean | null;
    cdfs?: string[];
  }) {
    return [
      balcony && t('lead:leadSummary.propertyDetails.features.balcony'),
      garden && t('lead:leadSummary.propertyDetails.features.garden'),
      parking && t('lead:leadSummary.propertyDetails.features.parking'),
      swimmingPool && t('lead:leadSummary.propertyDetails.features.swimmingPool'),
      guestToilet && t('lead:leadSummary.propertyDetails.features.guestToilet'),
      elevator && t('lead:leadSummary.propertyDetails.features.elevator'),
      garage && t('lead:leadSummary.propertyDetails.features.garage'),
      ...(cdfs ?? []),
    ]
      .filter(Boolean)
      .join(', ');
  }

  return {
    getAreaRangeValues,
    getAreaValues,
    collapseAreaValues,
    collapseRoomNumbers,
    collapseRoomStrings,
    getFurtherValues,
    collapseFurtherValues,
    collapseFeatureValues,
    getRentRanges,
    getRoomRanges,
  };
}
