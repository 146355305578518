import LinearProgress from '@mui/material/LinearProgress';
import { useShops } from 'api/graphql/hooks/useShops';
import { createContext, PropsWithChildren, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'util/i18next';
import { usePermissions } from 'util/usePermissions';

import { languageMatchRegex, signOut, useJwtToken } from '@ev/eva-container-api';
import { LicensePartner, Shop, ShopSettings } from 'api/graphql/generated/graphql';
import { useCurrentAgent } from 'api/graphql/hooks/useCurrentAgent';
import { useLicensePartners } from 'api/graphql/hooks/useLicencePartner';
import { useShopSettings } from 'api/graphql/hooks/useShopSettings';
import { env } from 'env';
import { ErrorPage } from 'eva-frame/ErrorPage';
import { useParams } from 'react-router-dom';
import { getTeamsWhereUserIsMember, parseRolesFromJwtToken } from 'util/keycloak/roles';
import { useDestination } from 'util/navigation/useDestination';
import { useNavigation } from 'util/navigation/useNavigation';

// Seller acquisition shop, this will only be for admins that aren't member of any shop
export const FALLBACK_SHOP_ID = {
  local: 'd82e319e-d738-4ef1-95ca-41b2004de406',
  feature: 'd82e319e-d738-4ef1-95ca-41b2004de406',
  dev: 'd82e319e-d738-4ef1-95ca-41b2004de406',
  stage: 'b2d19e9b-024f-461b-8b9f-35f0fe5e2de8',
  prod: '1c137ece-55ae-4837-abfc-903e429537ab',
  'migration-prod': '1c137ece-55ae-4837-abfc-903e429537ab',
};

export type ShopContextData = {
  activeShop: Shop;
  activeShopSettings?: ShopSettings;
  allShops: Shop[];
  activeLicensePartner: LicensePartner;
};

export const ShopContext = createContext<ShopContextData>({} as ShopContextData);

export function ActiveShopProvider({ children }: PropsWithChildren) {
  const { replace } = useNavigation();
  const { activeShopId, setActiveShopId } = useActiveShopId();

  const { t } = useTranslation(['user']);
  const { jwtToken } = useJwtToken();
  const { shopIds } = parseRolesFromJwtToken(jwtToken);
  const { canSeeAllShops, canAccessBlockedShops } = usePermissions();
  const { to404 } = useDestination();

  const { currentAgent, isError: isErrorAgent } = useCurrentAgent();

  const primaryShopId = currentAgent?.primaryShopId;

  // Admins typically are not members of any shop therefore we need to explicitly load the shop from the URL or a default shop
  const directlyLoadedShopId = canSeeAllShops()
    ? activeShopId || primaryShopId || FALLBACK_SHOP_ID[env.VITE_LEADHUB_ENV]
    : undefined;

  // Load all shops that the users is a member plus the directly loaded shop for admins
  const {
    shops: shopsIncludingBlocked,
    isLoading: isLoadingShops,
    isError: isErrorShops,
  } = useShops(
    {
      where: {
        status: { _nin: ['CLOSED'] },

        id: {
          _in: [...(shopIds || []), ...(directlyLoadedShopId ? [directlyLoadedShopId] : [])],
        },
      },
      order_by: { createdAt: 'DESC' },
    },
    { enabled: !!currentAgent },
  );

  // We load the block, but prevent access below
  const nonBlockedShops = useMemo(
    () => shopsIncludingBlocked?.filter((shop) => shop.status !== 'BLOCKED'),
    [shopsIncludingBlocked],
  );

  // check it the shop in URL can be found in the loaded shops
  const activeShop = shopsIncludingBlocked?.find((shop) => shop.id === activeShopId);

  // Validate the shop ID in the URL
  const isActiveShopIdInvalid =
    activeShopId && !activeShopId.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i);
  useEffect(() => {
    // If the URL is invalid redirect to 404 page
    if (isActiveShopIdInvalid) {
      replace(to404());
    }
  }, [to404, isActiveShopIdInvalid, replace]);

  // If there is no shop id in the URL redirect to a default shop - preferably the primary shop
  useEffect(() => {
    const defaultShop =
      shopsIncludingBlocked?.find((shop) => shop.id === primaryShopId) ||
      nonBlockedShops?.[0] ||
      shopsIncludingBlocked?.[0];

    if (defaultShop && !activeShopId) {
      // no active shop redirect to default
      const newPathname = pathWithDefaultShopIdBuilder(defaultShop);
      replace(newPathname);
    } else if (defaultShop && !activeShop) {
      // active shop is not accessible redirect to default
      setActiveShopId(defaultShop.id);
    }
  }, [nonBlockedShops, activeShop, setActiveShopId, shopsIncludingBlocked, replace, activeShopId, primaryShopId]);

  const {
    licensePartners,
    isLoading: isLoadingLicensePartner,
    isError: isErrorLicensePartner,
  } = useLicensePartners(
    {
      limit: 1,
      where: { shops: { id: { _eq: activeShopId } } },
    },
    { enabled: !!activeShopId },
  );

  const {
    shopSettings: activeShopSettings,
    isError: isErrorShopSettings,
    isLoading: isLoadingShopSettings,
  } = useShopSettings({ where: { shopId: { _eq: activeShopId } } }, { enabled: !!activeShopId });

  const activeLicensePartner = licensePartners?.[0];

  if (isLoadingShops || isActiveShopIdInvalid || isLoadingShopSettings || isLoadingLicensePartner) {
    return <LinearProgress />;
  }

  if (isErrorShops || isErrorShopSettings || isErrorLicensePartner || isErrorAgent) {
    return (
      <ErrorPage
        title={t('user:error.fetchShopError.title')}
        message={t('user:error.fetchShopError.message')}
        buttonText={t('user:error.fetchShopError.button')}
        onClick={() => location.reload()}
      />
    );
  }

  // There is no shop that the user is allowed to access
  if (!shopsIncludingBlocked?.length) {
    return (
      <ErrorPage
        title={t('user:error.noShopsError.title')}
        message={t('user:error.noShopsError.message')}
        buttonText={t('user:error.noShopsError.button')}
        onClick={() => signOut()}
      />
    );
  }

  if (!activeShop || !activeLicensePartner) {
    return <LinearProgress />;
  }

  if (activeShop?.status === 'BLOCKED' && !canAccessBlockedShops()) {
    return (
      <ShopContext.Provider
        value={{
          activeShop,
          activeShopSettings,
          allShops: nonBlockedShops!,
          activeLicensePartner,
        }}
      >
        <ErrorPage
          title={t('user:error.shopIsMigrated.title')}
          message={t('user:error.shopIsMigrated.message')}
          buttonText={t('user:error.shopIsMigrated.button')}
          onClick={() => location.reload()}
        />
      </ShopContext.Provider>
    );
  }

  const accessibleShops = canAccessBlockedShops() ? shopsIncludingBlocked : nonBlockedShops;

  return (
    <ShopContext.Provider
      value={{
        activeShop,
        activeShopSettings,
        allShops: accessibleShops!,
        activeLicensePartner,
      }}
    >
      {children}
    </ShopContext.Provider>
  );
}
export function useActiveShop() {
  const { activeShop, activeShopSettings, allShops, activeLicensePartner } = useContext(ShopContext);
  const { jwtToken } = useJwtToken();

  const teamsWhereUserIsMember = useMemo(
    () => (activeShop ? getTeamsWhereUserIsMember(activeShop, jwtToken) : []),
    [activeShop, jwtToken],
  );

  const isFullyOnboardedShop = activeShop?.onboardingLevel === 2;
  const isLevelOneShop = activeShop?.onboardingLevel === 1;

  const shopIdsInActiveGroup = useMemo(() => {
    if (!activeShop) return [];

    const groupShopIds = activeShop.groupId
      ? activeShop.shopGroup?.shops?.filter((shop) => shop.status !== 'CLOSED').map((shop) => shop.id)
      : [activeShop.id];

    return groupShopIds || [];
  }, [activeShop]);

  return {
    activeShop,
    shopCurrency: activeShop?.currency || 'EUR',
    shopUnitSystem: activeShop?.unitSystem || 'METRIC',
    shopRentUnit: activeShop?.rentUnit || 'MONTHLY',
    activeShopSettings,
    allShops,
    teamsWhereUserIsMember,
    isFullyOnboardedShop,
    isLevelOneShop,
    shopIdsInActiveGroup,
    activeLicensePartner,
  };
}

function pathWithDefaultShopIdBuilder(defaultShop: Shop) {
  // Extract the language part from the URL pathname, if present
  const urlLanguageMatch = window.location.pathname.match(languageMatchRegex);
  let newPathname;

  // Check if a language part exists in the URL
  if (urlLanguageMatch?.[1]) {
    const languagePart = urlLanguageMatch[1];
    const matchIndex = window.location.pathname.indexOf(languagePart) + languagePart.length;
    newPathname = `${window.location.pathname.substring(0, matchIndex)}/${
      defaultShop.id
    }${window.location.pathname.substring(matchIndex)}`;
  } else {
    // If no language part exists, insert the default shop ID after the first slash
    const firstSlashIndex = window.location.pathname.indexOf('/');
    const pathAfterFirstSlash = window.location.pathname.substring(firstSlashIndex + 1);
    newPathname = `${window.location.pathname.substring(0, firstSlashIndex + 1)}${defaultShop.id}${
      pathAfterFirstSlash ? '/' : ''
    }${pathAfterFirstSlash}`;
  }
  return newPathname;
}

export function useActiveShopId() {
  const { replace } = useNavigation();
  const { shopId: currentShopId } = useParams();
  const { toHomePage } = useDestination();

  return useMemo(
    () => ({
      setActiveShopId(shopId: string) {
        if (currentShopId === shopId) {
          return;
        }
        // Clear session storage for GOme
        sessionStorage.clear();
        replace(toHomePage({ shopId }));
      },
      activeShopId: currentShopId,
    }),
    [currentShopId, toHomePage, replace],
  );
}
