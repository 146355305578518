import { ExposeTemplateType } from '@ev/eva-container-api';
import {
  ActivityTypeEnum,
  AddressTypeEnum,
  AppointmentTypeEnum,
  AssetConditionEnum,
  AssetSubTypeEnum,
  AssetTypeEnum,
  AssetVariationEnum,
  BaseActivityCategoryEnum,
  ConsentSourceEnum,
  ConsentStatusEnum,
  ConsentTypeEnum,
  ContactRelationTypeEnum,
  ContactTimeEnum,
  ContactTypeEnum,
  EmailAddressTypeEnum,
  EmailIntegrationScopeEnum,
  EmailPlaceholderTypeEnum,
  EmailSignatureScopeEnum,
  EmailTemplateScopeEnum,
  EntityTypeEnum,
  HouseholdTypeEnum,
  IntentCategoryEnum,
  IntentTypeEnum,
  LeadSourceEnum,
  LeadStatusEnum,
  LeadStatusReasonEnum,
  MarketingTypeEnum,
  PhoneNumberTypeEnum,
  PriorityEnum,
  PrivacyLevelEnum,
  ProfessionTypeEnum,
  PropertyEngineListingStatus,
  PropertyEngineListingType,
  PropertyEngineMatchingState,
  PropertyOccupationStatusEnum,
  PropertyOwnershipStatusEnum,
  ReferralNotPossibleReasonEnum,
  SalutationEnum,
  ShopExpertiseEnum,
  ShopStatusEnum,
  TaskAppointmentStatusEnum,
  TaskTypeEnum,
  TimeFrameEnum,
  UserRoleEnum,
  ZeroCommissionReasonEnum,
} from 'api/graphql/generated/graphql';
import { KycRiskLevelEnum } from 'api/rest/models/KycCheckResponse';
import { Go3LeadStatus } from 'api/rest/models/go3/Go3LeadStatus';
import { CallOutcome } from 'components/state/Twilio';
import { CallType } from 'page-components/calls/CallPage';
import { IntentTabEnum } from 'util/intentType';

export const activityTypeTranslationKeys = {
  CALL: 'enums:activityType.CALL',
  CONTACT_ACTIVATED: 'enums:activityType.CONTACT_ACTIVATED',
  CONTACT_BLOCKED: 'enums:activityType.CONTACT_BLOCKED',
  CONTACT_UNBLOCKED: 'enums:activityType.CONTACT_UNBLOCKED',
  CONTACT_CREATED: 'enums:activityType.CONTACT_CREATED',
  CONTACT_MERGED: 'enums:activityType.CONTACT_MERGED',
  CONTACT_UPDATED: 'enums:activityType.CONTACT_UPDATED',
  CUSTOMER_ACCOUNT_UPDATED: 'enums:activityType.CUSTOMER_ACCOUNT_UPDATED',
  EMAIL_LINKING_TO_LEAD: 'enums:activityType.EMAIL_LINKING_TO_LEAD',
  EMAIL_LINKING_TO_CONTACT: 'enums:activityType.EMAIL_LINKING_TO_CONTACT',
  EMAIL_DELINKING_TO_CONTACT: 'enums:activityType.EMAIL_DELINKING_TO_CONTACT',
  EMAIL_DELINKING_TO_LEAD: 'enums:activityType.EMAIL_DELINKING_TO_LEAD',
  INCOMING_CALL: 'enums:activityType.INCOMING_CALL',
  INCOMING_EMAIL: 'enums:activityType.INCOMING_EMAIL',
  LEAD_ASSIGNED_TO_AGENT: 'enums:activityType.LEAD_ASSIGNED_TO_AGENT',
  LEAD_CREATED: 'enums:activityType.LEAD_CREATED',
  LEAD_UPDATED: 'enums:activityType.LEAD_UPDATED',
  LEAD_QUALIFIED: 'enums:activityType.LEAD_QUALIFIED',
  LEAD_QUALIFICATION_FORM_SUBMITTED: 'enums:activityType.LEAD_QUALIFICATION_FORM_SUBMITTED',
  LEAD_REFERRAL_CUSTOMER_OUTGOING_EMAIL: 'enums:activityType.LEAD_REFERRAL_CUSTOMER_OUTGOING_EMAIL',
  LEAD_REFERRAL_SHOP_OUTGOING_EMAIL: 'enums:activityType.LEAD_REFERRAL_SHOP_OUTGOING_EMAIL',
  REFERRAL_SENT: 'enums:activityType.REFERRAL_SENT',
  MISSED_CALL: 'enums:activityType.MISSED_CALL',
  OUTGOING_CALL: 'enums:activityType.OUTGOING_CALL',
  OUTGOING_CALL_API: 'enums:activityType.OUTGOING_CALL_API',
  OUTGOING_EMAIL: 'enums:activityType.OUTGOING_EMAIL',
  SHOP_OUTGOING_CALL: 'enums:activityType.SHOP_OUTGOING_CALL',
  LEAD_QUALIFICATION_FORM_SENT: 'enums:activityType.LEAD_QUALIFICATION_FORM_SENT',
  LEAD_QUALIFICATION_REMINDER_SENT: 'enums:activityType.LEAD_QUALIFICATION_REMINDER_SENT',
  LEAD_QUALIFICATION_FORM_RESENT: 'enums:activityType.LEAD_QUALIFICATION_FORM_RESENT',
  LEAD_QUALIFICATION_COMMISSION_CONTRACT_EMAIL_SENT:
    'enums:activityType.LEAD_QUALIFICATION_COMMISSION_CONTRACT_EMAIL_SENT',
  LEAD_QUALIFICATION_PROPERTY_NOT_AVAILABLE_EMAIL_SENT:
    'enums:activityType.LEAD_QUALIFICATION_PROPERTY_NOT_AVAILABLE_EMAIL_SENT',
  LEAD_QUALIFICATION_WITH_MISSING_WITHDRAWAL_CONSENT_EMAIL_SENT:
    'enums:activityType.LEAD_QUALIFICATION_WITH_MISSING_WITHDRAWAL_CONSENT_EMAIL_SENT',
  SHARED_LEAD: 'enums:activityType.SHARED_LEAD',
  EXPOSE_SENT: 'enums:activityType.EXPOSE_SENT',
  EXPOSE: 'enums:activityType.EXPOSE',
  CONSENT_REQUESTED: 'enums:activityType.CONSENT_REQUESTED',
  CONSENT_REJECTED: 'enums:activityType.CONSENT_REJECTED',
  CONSENT_UPDATED: 'enums:activityType.CONSENT_UPDATED',
  CONSENT_CONFIRMED: 'enums:activityType.CONSENT_CONFIRMED',
  CONSENT_REVOKED: 'enums:activityType.CONSENT_REVOKED',
  CONSENT_EXPIRED: 'enums:activityType.CONSENT_EXPIRED',
  CONSENT_CONFIRMATION_MAIL_SENT: 'enums:activityType.CONSENT_CONFIRMATION_MAIL_SENT',
  AGENT_ASSIGNED_TO_CONTACT: 'enums:activityType.AGENT_ASSIGNED_TO_CONTACT',
  AGENT_REASSIGNED_TO_CONTACT: 'enums:activityType.AGENT_REASSIGNED_TO_CONTACT',
  CONTACT_LINKED: 'enums:activityType.CONTACT_LINKED',
  IMS_EMAIL_SENT: 'enums:activityType.IMS_EMAIL_SENT',
  APPOINTMENT_CREATED: 'enums:activityType.APPOINTMENT_CREATED',
  APPOINTMENT_UPDATED: 'enums:activityType.APPOINTMENT_UPDATED',
  TASK_CREATED: 'enums:activityType.TASK_CREATED',
  TASK_UPDATED: 'enums:activityType.TASK_UPDATED',
  LETTER: 'enums:activityType.LETTER',
  VIEWING_REPORT: 'enums:activityType.VIEWING_REPORT',
  DOCUMENT_CREATED: 'enums:activityType.DOCUMENT_CREATED',
  DOCUMENT_DELETED: 'enums:activityType.DOCUMENT_DELETED',
  DOCUMENT_MODIFIED: 'enums:activityType.DOCUMENT_MODIFIED',
  DOCUMENT_PRINTED: 'enums:activityType.DOCUMENT_PRINTED',
  VISIT_CONFIRMATION_SENT: 'enums:activityType.VISIT_CONFIRMATION_SENT',
  OTHERS: 'enums:activityType.OTHERS',
  LEGACY_GO3_CHANGE: 'enums:activityType.LEGACY_GO3_CHANGE',
  MIGRATION_PATCHING: 'enums:activityType.MIGRATION_PATCHING',
  LINKED_TO_PROPERTY: 'enums:activityType.LINKED_TO_PROPERTY',
  LINKED_TO_SEARCH_DEMAND: 'enums:activityType.LINKED_TO_SEARCH_DEMAND',
  UNLINKED_FROM_PROPERTY: 'enums:activityType.UNLINKED_FROM_PROPERTY',
  UNLINKED_FROM_SEARCH_DEMAND: 'enums:activityType.UNLINKED_FROM_SEARCH_DEMAND',
  OUTGOING_CC: 'enums:activityType.OUTGOING_CC',
  EXPOSE_SENT_CC: 'enums:activityType.EXPOSE_SENT_CC',
  ACTIVITIES_BY_CONTACT_EXPORTED: 'enums:activityType.ACTIVITIES_BY_CONTACT_EXPORTED',
  ACTIVITIES_BY_PROPERTY_EXPORTED: 'enums:activityType.ACTIVITIES_BY_PROPERTY_EXPORTED',
  VIEWING_REPORT_CC: 'enums:activityType.VIEWING_REPORT_CC',
  CONTACTS_BY_AGENT_EXPORTED: 'enums:activityType.CONTACTS_BY_AGENT_EXPORTED',
  NEWSLETTER_RECIPIENTS_EXPORTED: 'enums:activityType.NEWSLETTER_RECIPIENTS_EXPORTED',
  POSTAL_CONTACTS_EXPORTED: 'enums:activityType.POSTAL_CONTACTS_EXPORTED',
  CONTACT_ADDED_TO_NEWSLETTER: 'enums:activityType.CONTACT_ADDED_TO_NEWSLETTER',
  CONTACT_NEWSLETTER_ADDITION_QUEUED: 'enums:activityType.CONTACT_NEWSLETTER_ADDITION_QUEUED',
} as const satisfies Record<ActivityTypeEnum, string>;

export const leadSourceTranslationKeys = {
  COLD_CALLING: 'enums:leadSource.COLD_CALLING',
  CUSTOMER_INITIATIVE: 'enums:leadSource.CUSTOMER_INITIATIVE',
  EVENT: 'enums:leadSource.EVENT',
  EXISTING_CONTACT: 'enums:leadSource.EXISTING_CONTACT',
  FLYER: 'enums:leadSource.FLYER',
  GG: 'enums:leadSource.GG',
  MIETE_AKTUELL: 'enums:leadSource.MIETE_AKTUELL',
  NEIGHBOURHOOD_FLYER: 'enums:leadSource.NEIGHBOURHOOD_FLYER',
  PAID_SEARCH: 'enums:leadSource.PAID_SEARCH',
  PRIVATE_CONTACT: 'enums:leadSource.PRIVATE_CONTACT',
  PROPERTY_VALUATION: 'enums:leadSource.PROPERTY_VALUATION',
  PUBLICATION: 'enums:leadSource.PUBLICATION',
  RECOMMENDATION: 'enums:leadSource.RECOMMENDATION',
  SELL_PROPERTY: 'enums:leadSource.SELL_PROPERTY',
  SIGNBOARD: 'enums:leadSource.SIGNBOARD',
  UNDEFINED: 'enums:leadSource.UNDEFINED',
  WALK_IN: 'enums:leadSource.WALK_IN',
  FOTOCASA: 'enums:leadSource.FOTOCASA',
  IDEALISTA: 'enums:leadSource.IDEALISTA',
  IMMOBILIARE: 'enums:leadSource.IMMOBILIARE',
  SPITOGATOS: 'enums:leadSource.SPITOGATOS',
  CHAT_WIDGET: 'enums:leadSource.CHAT_WIDGET',
  COLLABORATION: 'enums:leadSource.COLLABORATION',
  ENALQUILER: 'enums:leadSource.ENALQUILER',
  EV_SEARCH: 'enums:leadSource.EV_SEARCH',
  EXHIBITION: 'enums:leadSource.EXHIBITION',
  FARMING: 'enums:leadSource.FARMING',
  HABITACLIA: 'enums:leadSource.HABITACLIA',
  HINTFROM: 'enums:leadSource.HINTFROM',
  HOLIDAY_MARKET_REPORT: 'enums:leadSource.HOLIDAY_MARKET_REPORT',
  IMMOSCOUT24: 'enums:leadSource.IMMOSCOUT24',
  JAMESEDITION: 'enums:leadSource.JAMESEDITION',
  KYERO: 'enums:leadSource.KYERO',
  LISTGLOBALLY: 'enums:leadSource.LISTGLOBALLY',
  MAILING: 'enums:leadSource.MAILING',
  MERETDEMEURES: 'enums:leadSource.MERETDEMEURES',
  OTHERS: 'enums:leadSource.OTHERS',
  PISOS: 'enums:leadSource.PISOS',
  PROPERTY_PRICES: 'enums:leadSource.PROPERTY_PRICES',
  RESEARCH_MARKET_REPORT: 'enums:leadSource.RESEARCH_MARKET_REPORT',
  XE: 'enums:leadSource.XE',
  YAECONTRE: 'enums:leadSource.YAECONTRE',
  SELOGER_LOGICIMMO: 'enums:leadSource.SELOGER_LOGICIMMO',
  RESIDENCES_IMMOBILIER: 'enums:leadSource.RESIDENCES_IMMOBILIER',
  BELLESD_LUXR: 'enums:leadSource.BELLESD_LUXR',
  FIGAROIMMO: 'enums:leadSource.FIGAROIMMO',
  PROPRIETES_FIGARO: 'enums:leadSource.PROPRIETES_FIGARO',
  MEILLEURSAGENTS: 'enums:leadSource.MEILLEURSAGENTS',
  MAISONS_APPARTEMENTS: 'enums:leadSource.MAISONS_APPARTEMENTS',
  ATHOME: 'enums:leadSource.ATHOME',
  CASASAPO: 'enums:leadSource.CASASAPO',
  HOMEGATE: 'enums:leadSource.HOMEGATE',
  IMMOWELT: 'enums:leadSource.IMMOWELT',
  LUXURYESTATE: 'enums:leadSource.LUXURYESTATE',
  MSW: 'enums:leadSource.MSW',
  NEWHOME: 'enums:leadSource.NEWHOME',
  VENTURE: 'enums:leadSource.VENTURE',
  WILLHABEN: 'enums:leadSource.WILLHABEN',
  HAUSFRAGE: 'enums:leadSource.HAUSFRAGE',
  RIGHTMOVE: 'enums:leadSource.RIGHTMOVE',
  WATTFOX: 'enums:leadSource.WATTFOX',
  GLOBALIZA: 'enums:leadSource.GLOBALIZA',
  INDOMIO: 'enums:leadSource.INDOMIO',
  TIPSTER: 'enums:leadSource.TIPSTER',
  IMMOBILIER_CH: 'enums:leadSource.IMMOBILIER_CH',
  INMOBILIARIAS_ES: 'enums:leadSource.INMOBILIARIAS_ES',
  GO3_MIGRATION: 'enums:leadSource.GO3_MIGRATION',
  GO3_MIGRATION_OWNER: 'enums:leadSource.GO3_MIGRATION_OWNER',
  GO3_MIGRATION_SEARCH_CLIENT: 'enums:leadSource.GO3_MIGRATION_SEARCH_CLIENT',
  IMMOSCOUT_2_0: 'enums:leadSource.IMMOSCOUT_2_0',
  REFERRAL: 'enums:leadSource.REFERRAL',
  REFERRAL_COMMERCIAL: 'enums:leadSource.REFERRAL_COMMERCIAL',
  ENTER: 'enums:leadSource.ENTER',
  AIRBNB: 'enums:leadSource.AIRBNB',
  HANDELSBLATT: 'enums:leadSource.HANDELSBLATT',
  MEMOVE: 'enums:leadSource.MEMOVE',
  VRBO: 'enums:leadSource.VRBO',
  VIVI: 'enums:leadSource.VIVI',
  NEWSLETTER: 'enums:leadSource.NEWSLETTER',
  ACHETER_LOUER: 'enums:leadSource.ACHETER_LOUER',
  CASA24: 'enums:leadSource.CASA24',
  CASASHARE: 'enums:leadSource.CASASHARE',
  DER_STANDARD: 'enums:leadSource.DER_STANDARD',
  DOMAZA: 'enums:leadSource.DOMAZA',
  EBAY: 'enums:leadSource.EBAY',
  FACEBOOK: 'enums:leadSource.FACEBOOK',
  FINANCIAL_TIMES: 'enums:leadSource.FINANCIAL_TIMES',
  FIND_MY_HOME: 'enums:leadSource.FIND_MY_HOME',
  GREEN_ACRESS: 'enums:leadSource.GREEN_ACRESS',
  HOLIDAY_LETTINGS_UK: 'enums:leadSource.HOLIDAY_LETTINGS_UK',
  HOME_CH: 'enums:leadSource.HOME_CH',
  HOSTIFY: 'enums:leadSource.HOSTIFY',
  ICASA: 'enums:leadSource.ICASA',
  IMMOVARIO: 'enums:leadSource.IMMOVARIO',
  IMMOWEB: 'enums:leadSource.IMMOWEB',
  IMMO_SPARROW: 'enums:leadSource.IMMO_SPARROW',
  IMMO_STREET: 'enums:leadSource.IMMO_STREET',
  IMMO_TT: 'enums:leadSource.IMMO_TT',
  INTERNETPORTALS: 'enums:leadSource.INTERNETPORTALS',
  INTERNET_PORTALS_OTHERS: 'enums:leadSource.INTERNET_PORTALS_OTHERS',
  LEADHUB: 'enums:leadSource.LEADHUB',
  LOGIC_IMMO: 'enums:leadSource.LOGIC_IMMO',
  LUX_RESIDENCE: 'enums:leadSource.LUX_RESIDENCE',
  MESSAGGERO_CASA: 'enums:leadSource.MESSAGGERO_CASA',
  MIO_AFFITTO: 'enums:leadSource.MIO_AFFITTO',
  NEO_ADVERTISING: 'enums:leadSource.NEO_ADVERTISING',
  OUTDOORADVERTISING: 'enums:leadSource.OUTDOORADVERTISING',
  PANOMATV: 'enums:leadSource.PANOMATV',
  PRIME_LOCATION: 'enums:leadSource.PRIME_LOCATION',
  RESIDENCES_IMMOBILIE: 'enums:leadSource.RESIDENCES_IMMOBILIE',
  SEGUNDAMANO: 'enums:leadSource.SEGUNDAMANO',
  SKY_SIGNAGE: 'enums:leadSource.SKY_SIGNAGE',
  SPITI24: 'enums:leadSource.SPITI24',
  TELEPHONEBOOK: 'enums:leadSource.TELEPHONEBOOK',
  THINK_SPAIN: 'enums:leadSource.THINK_SPAIN',
  URBANHOME: 'enums:leadSource.URBANHOME',
  MIMOVE: 'enums:leadSource.MIMOVE',
  SUPERCASA: 'enums:leadSource.SUPERCASA',
  ITALIAN_HOUSES_FOR_SALE: 'enums:leadSource.ITALIAN_HOUSES_FOR_SALE',
  VILLE_E_CASALI: 'enums:leadSource.VILLE_E_CASALI',
  SECOND_HOME_BEURS: 'enums:leadSource.SECOND_HOME_BEURS',
  TRANIO: 'enums:leadSource.TRANIO',
  PRIVATE_OFFICE: 'enums:leadSource.PRIVATE_OFFICE',
  PROPERTYFINDER: 'enums:leadSource.PROPERTYFINDER',
  DUBIZZLE: 'enums:leadSource.DUBIZZLE',
  BAYUT: 'enums:leadSource.BAYUT',
  GOOGLE: 'enums:leadSource.GOOGLE',
  EMAIL: 'enums:leadSource.EMAIL',
  EV_EVENT: 'enums:leadSource.EV_EVENT',
  FLYER_AGENT_DISTRIBUTION: 'enums:leadSource.FLYER_AGENT_DISTRIBUTION',
  OUT_OF_HOME_ADS: 'enums:leadSource.OUT_OF_HOME_ADS',
  PHONE_CALL: 'enums:leadSource.PHONE_CALL',
  POSTAL_MAILING: 'enums:leadSource.POSTAL_MAILING',
  PRINT_ADS: 'enums:leadSource.PRINT_ADS',
  SPONSORING: 'enums:leadSource.SPONSORING',
} as const satisfies Record<LeadSourceEnum, string>;

export const emailSignatureScopeTranslationKeys = {
  PERSONAL: 'enums:emailTemplateSignatureOrIntegrationScope.PERSONAL',
  TEAM: 'enums:emailTemplateSignatureOrIntegrationScope.TEAM',
  SHOP: 'enums:emailTemplateSignatureOrIntegrationScope.SHOP',
  GLOBAL: 'enums:emailTemplateSignatureOrIntegrationScope.GLOBAL',
} as const satisfies Record<EmailSignatureScopeEnum, string>;

export const emailTemplateScopeTranslationKeys = {
  PERSONAL: 'enums:emailTemplateSignatureOrIntegrationScope.PERSONAL',
  TEAM: 'enums:emailTemplateSignatureOrIntegrationScope.TEAM',
  SHOP: 'enums:emailTemplateSignatureOrIntegrationScope.SHOP',
  GLOBAL: 'enums:emailTemplateSignatureOrIntegrationScope.GLOBAL',
} as const satisfies Record<EmailTemplateScopeEnum, string>;

export const emailIntegrationScopeTranslationKeys = {
  PERSONAL: 'enums:emailTemplateSignatureOrIntegrationScope.PERSONAL',
  TEAM: 'enums:emailTemplateSignatureOrIntegrationScope.TEAM',
  SHOP: 'enums:emailTemplateSignatureOrIntegrationScope.SHOP',
} as const satisfies Record<EmailIntegrationScopeEnum, string>;

export const ROLE_TRANSLATION = {
  SHOP_MANAGER: 'enums:role.shopManager',
  LEAD_MANAGER: 'enums:role.leadManager',
  CALL_CENTER_AGENT: 'enums:role.callcenterAgent',
  TEAM_LEAD: 'enums:role.teamLead',
  TEAM_LEAD_LIGHT: 'enums:role.teamLeadLight',
  TEAM_ASSISTANT: 'enums:role.teamAssistant',
  TEAM_ASSISTANT_LIGHT: 'enums:role.teamAssistantLight',
  AGENT: 'enums:role.agent',
  AGENT_LIGHT: 'enums:role.agentLight',
  ADMIN: 'enums:role.admin',
  READONLY_ADMIN: 'enums:role.readonlyAdmin',
  COMPLIANCE_MANAGER: 'enums:role.complianceManager',
  CONTENT_MANAGER: 'enums:role.contentManager',
} as const satisfies Record<UserRoleEnum, string>;

export const timeFrameTranslationKeys = {
  ONE_MONTH: 'enums:timeFrame.ONE_MONTH',
  TWO_MONTHS: 'enums:timeFrame.TWO_MONTH',
  THREE_MONTHS: 'enums:timeFrame.THREE_MONTH',
  SIX_MONTHS: 'enums:timeFrame.SIX_MONTH',
  TWELVE_MONTHS: 'enums:timeFrame.TWELVE_MONTHS',
} as const satisfies Record<TimeFrameEnum, string>;

export const propertyOwnershipStatusTranslationKeys = {
  LH_CONTACT: 'enums:propertyOwnershipStatus.LH_CONTACT',
  OTHER: 'enums:propertyOwnershipStatus.OTHER',
  OWNER: 'enums:propertyOwnershipStatus.OWNER',
} as const satisfies Record<PropertyOwnershipStatusEnum, string>;

export const propertyOccupationStatusTranslationKeys = {
  OWNER: 'enums:propertyOccupationStatus.OWNER',
  RENTED_OUT: 'enums:propertyOccupationStatus.RENTED_OUT',
  EMPTY: 'enums:propertyOccupationStatus.EMPTY',
} as const satisfies Record<PropertyOccupationStatusEnum, string>;

export const intentTypeTranslation = {
  BUY: 'enums:intentType.BUY',
  RENT_OUT: 'enums:intentType.RENT_OUT',
  SELL: 'enums:intentType.SELL',
  TO_RENT: 'enums:intentType.TO_RENT',
  UNDEFINED: 'enums:intentType.UNDEFINED',
  VALUATION: 'enums:intentType.VALUATION',
} as const satisfies Record<IntentTypeEnum, string>;

export const propertyEngineListingTypeTranslation = {
  RENTAL: 'enums:propertyEngineListingType.RENTAL',
  SALE: 'enums:propertyEngineListingType.SALE',
  DEVELOPMENT: 'enums:propertyEngineListingType.DEVELOPMENT',
} as const satisfies Record<PropertyEngineListingType, string>;

export const assetTypeTranslation = {
  APARTMENT: 'enums:assetType.APARTMENT',
  HOUSE: 'enums:assetType.HOUSE',
  PLOT: 'enums:assetType.PLOT',
} as const satisfies Record<AssetTypeEnum, string>;

export const leadStatusReasonTranslation = {
  NO_CONTACT_INFORMATION: 'enums:leadStatusReason.NO_CONTACT_INFORMATION',
  FAKE_LEAD: 'enums:leadStatusReason.FAKE_LEAD',
  COLLABORATOR: 'enums:leadStatusReason.COLLABORATOR',
  OTHERS: 'enums:leadStatusReason.OTHERS',
  NOT_DEFINED: 'enums:leadStatusReason.NOT_DEFINED',
  NO_CUSTOMER_RESPONSE: 'enums:leadStatusReason.NO_CUSTOMER_RESPONSE',
  CUSTOMER_REVOKED_REQUEST: 'enums:leadStatusReason.CUSTOMER_REVOKED_REQUEST',
  DUPLICATE_LEAD: 'enums:leadStatusReason.DUPLICATE_LEAD',
  NO_SHOP_IN_AREA: 'enums:leadStatusReason.NO_SHOP_IN_AREA',
  COMMERCIAL: 'enums:leadStatusReason.COMMERCIAL',
  CUSTOMER_BLOCKED: 'enums:leadStatusReason.CUSTOMER_BLOCKED',
  CUSTOMER_UNBLOCKED: 'enums:leadStatusReason.CUSTOMER_UNBLOCKED',
} as const satisfies Record<LeadStatusReasonEnum | 'NOT_DEFINED', string>;

export const emailPlaceholderTranslation = {
  CONTACT_ID: 'enums:emailPlaceholder.CONTACT_ID',
  CONTACT_FULL_ADDRESS: 'enums:emailPlaceholder.CONTACT_FULL_ADDRESS',
  CONTACT_EMAIL_ADDRESSES: 'enums:emailPlaceholder.CONTACT_EMAIL_ADDRESSES',
  CONTACT_FIRST_NAME: 'enums:emailPlaceholder.CONTACT_FIRST_NAME',
  CONTACT_LAST_NAME: 'enums:emailPlaceholder.CONTACT_LAST_NAME',
  CONTACT_GENERAL_COMMENT: 'enums:emailPlaceholder.CONTACT_GENERAL_COMMENT',
  CONTACT_PHONE_NUMBERS: 'enums:emailPlaceholder.CONTACT_PHONE_NUMBERS',
  CONTACT_PREF_EMAIL_ADDRESS: 'enums:emailPlaceholder.CONTACT_PREF_EMAIL_ADDRESS',
  CONTACT_PREF_PHONE_NUMBER: 'enums:emailPlaceholder.CONTACT_PREF_PHONE_NUMBER',
  CONTACT_TITLE: 'enums:emailPlaceholder.CONTACT_TITLE',
  CONTACT_SALUTATION: 'enums:emailPlaceholder.CONTACT_SALUTATION',
  CONTACT_FORMATTED_SALUTATION: 'enums:emailPlaceholder.CONTACT_FORMATTED_SALUTATION',
  CONTACT_ADDRESS_STREET_NAME: 'enums:emailPlaceholder.CONTACT_ADDRESS_STREET_NAME',
  CONTACT_ADDRESS_STREET_NUMBER: 'enums:emailPlaceholder.CONTACT_ADDRESS_STREET_NUMBER',
  CONTACT_ADDRESS_POSTAL_CODE: 'enums:emailPlaceholder.CONTACT_ADDRESS_POSTAL_CODE',
  CONTACT_ADDRESS_CITY: 'enums:emailPlaceholder.CONTACT_ADDRESS_CITY',
  CONTACT_ADDRESS_COUNTRY: 'enums:emailPlaceholder.CONTACT_ADDRESS_COUNTRY',
  CONTACT_PREFERRED_LANGUAGE: 'enums:emailPlaceholder.CONTACT_PREFERRED_LANGUAGE',
  CURRENT_DATE: 'enums:emailPlaceholder.CURRENT_DATE',
  OWN_SHOP_FULL_ADDRESS: 'enums:emailPlaceholder.OWN_SHOP_FULL_ADDRESS',
  OWN_SHOP_ADDRESS_CITY: 'enums:emailPlaceholder.OWN_SHOP_ADDRESS_CITY',
  OWN_SHOP_ADDRESS_COUNTRY: 'enums:emailPlaceholder.OWN_SHOP_ADDRESS_COUNTRY',
  OWN_SHOP_ADDRESS_POSTAL_CODE: 'enums:emailPlaceholder.OWN_SHOP_ADDRESS_POSTAL_CODE',
  OWN_SHOP_ADDRESS_STREET_NAME: 'enums:emailPlaceholder.OWN_SHOP_ADDRESS_STREET_NAME',
  OWN_SHOP_ADDRESS_STREET_NUMBER: 'enums:emailPlaceholder.OWN_SHOP_ADDRESS_STREET_NUMBER',
  OWN_SHOP_EMAIL_ADDRESS: 'enums:emailPlaceholder.OWN_SHOP_EMAIL_ADDRESS',
  OWN_SHOP_LEGAL_ENTITY: 'enums:emailPlaceholder.OWN_SHOP_LEGAL_ENTITY',
  OWN_SHOP_NAME: 'enums:emailPlaceholder.OWN_SHOP_NAME',
  OWN_SHOP_PHONE_NUMBER: 'enums:emailPlaceholder.OWN_SHOP_PHONE_NUMBER',
  OWN_SHOP_WEBSITE_URL: 'enums:emailPlaceholder.OWN_SHOP_WEBSITE_URL',
  OWN_SHOP_PRIVACY_POLICY_URL: 'enums:emailPlaceholder.OWN_SHOP_PRIVACY_POLICY_URL',
  OWN_SHOP_IMPRINT_URL: 'enums:emailPlaceholder.OWN_SHOP_IMPRINT_URL',
  ACTIVITY_VIEWING_DATE: 'enums:emailPlaceholder.ACTIVITY_VIEWING_DATE',
  ACTIVITY_VIEWING_FEEDBACK: 'enums:emailPlaceholder.ACTIVITY_VIEWING_FEEDBACK',
  PROPERTY_EXPOSE_LIST: 'enums:emailPlaceholder.PROPERTY_EXPOSE_LIST',
  PROPERTY_SECOND_CITY: 'enums:emailPlaceholder.PROPERTY_SECOND_CITY',
  PROPERTY_SECOND_CONDITIONAL: 'enums:emailPlaceholder.PROPERTY_SECOND_CONDITIONAL',
  PROPERTY_SECOND_COUNTRY: 'enums:emailPlaceholder.PROPERTY_SECOND_COUNTRY',
  PROPERTY_SECOND_FULL_ADDRESS: 'enums:emailPlaceholder.PROPERTY_SECOND_FULL_ADDRESS',
  PROPERTY_SECOND_SECOND_ADDRESS_LINE: 'enums:emailPlaceholder.PROPERTY_SECOND_SECOND_ADDRESS_LINE',
  PROPERTY_SECOND_ID: 'enums:emailPlaceholder.PROPERTY_SECOND_ID',
  PROPERTY_SECOND_GOME_LINK: 'enums:emailPlaceholder.PROPERTY_SECOND_GOME_LINK',
  PROPERTY_SECOND_POSTAL_CODE: 'enums:emailPlaceholder.PROPERTY_SECOND_POSTAL_CODE',
  PROPERTY_SECOND_PRICE: 'enums:emailPlaceholder.PROPERTY_SECOND_PRICE',
  PROPERTY_SECOND_STREET_NAME: 'enums:emailPlaceholder.PROPERTY_SECOND_STREET_NAME',
  PROPERTY_SECOND_STREET_NUMBER: 'enums:emailPlaceholder.PROPERTY_SECOND_STREET_NUMBER',
  PROPERTY_SECOND_TITLE: 'enums:emailPlaceholder.PROPERTY_SECOND_TITLE',
  PROPERTY_SECOND_IMAGE: 'enums:emailPlaceholder.PROPERTY_SECOND_IMAGE',
  PROPERTY_SECOND_OWNER: 'enums:emailPlaceholder.PROPERTY_SECOND_OWNER',
  PROPERTY_THIRD_CITY: 'enums:emailPlaceholder.PROPERTY_THIRD_CITY',
  PROPERTY_THIRD_CONDITIONAL: 'enums:emailPlaceholder.PROPERTY_THIRD_CONDITIONAL',
  PROPERTY_THIRD_COUNTRY: 'enums:emailPlaceholder.PROPERTY_THIRD_COUNTRY',
  PROPERTY_THIRD_FULL_ADDRESS: 'enums:emailPlaceholder.PROPERTY_THIRD_FULL_ADDRESS',
  PROPERTY_THIRD_SECOND_ADDRESS_LINE: 'enums:emailPlaceholder.PROPERTY_THIRD_SECOND_ADDRESS_LINE',
  PROPERTY_THIRD_ID: 'enums:emailPlaceholder.PROPERTY_THIRD_ID',
  PROPERTY_THIRD_GOME_LINK: 'enums:emailPlaceholder.PROPERTY_THIRD_GOME_LINK',
  PROPERTY_THIRD_POSTAL_CODE: 'enums:emailPlaceholder.PROPERTY_THIRD_POSTAL_CODE',
  PROPERTY_THIRD_PRICE: 'enums:emailPlaceholder.PROPERTY_THIRD_PRICE',
  PROPERTY_THIRD_STREET_NAME: 'enums:emailPlaceholder.PROPERTY_THIRD_STREET_NAME',
  PROPERTY_THIRD_STREET_NUMBER: 'enums:emailPlaceholder.PROPERTY_THIRD_STREET_NUMBER',
  PROPERTY_THIRD_TITLE: 'enums:emailPlaceholder.PROPERTY_THIRD_TITLE',
  PROPERTY_THIRD_IMAGE: 'enums:emailPlaceholder.PROPERTY_THIRD_IMAGE',
  PROPERTY_THIRD_OWNER: 'enums:emailPlaceholder.PROPERTY_THIRD_OWNER',
  PROPERTY_FIRST_CITY: 'enums:emailPlaceholder.PROPERTY_FIRST_CITY',
  PROPERTY_FIRST_CONDITIONAL: 'enums:emailPlaceholder.PROPERTY_FIRST_CONDITIONAL',
  PROPERTY_FIRST_COUNTRY: 'enums:emailPlaceholder.PROPERTY_FIRST_COUNTRY',
  PROPERTY_FIRST_FULL_ADDRESS: 'enums:emailPlaceholder.PROPERTY_FIRST_FULL_ADDRESS',
  PROPERTY_FIRST_SECOND_ADDRESS_LINE: 'enums:emailPlaceholder.PROPERTY_FIRST_SECOND_ADDRESS_LINE',
  PROPERTY_FIRST_ID: 'enums:emailPlaceholder.PROPERTY_FIRST_ID',
  PROPERTY_FIRST_GOME_LINK: 'enums:emailPlaceholder.PROPERTY_FIRST_GOME_LINK',
  PROPERTY_FIRST_POSTAL_CODE: 'enums:emailPlaceholder.PROPERTY_FIRST_POSTAL_CODE',
  PROPERTY_FIRST_PRICE: 'enums:emailPlaceholder.PROPERTY_FIRST_PRICE',
  PROPERTY_FIRST_STREET_NAME: 'enums:emailPlaceholder.PROPERTY_FIRST_STREET_NAME',
  PROPERTY_FIRST_STREET_NUMBER: 'enums:emailPlaceholder.PROPERTY_FIRST_STREET_NUMBER',
  PROPERTY_FIRST_TITLE: 'enums:emailPlaceholder.PROPERTY_FIRST_TITLE',
  PROPERTY_FIRST_IMAGE: 'enums:emailPlaceholder.PROPERTY_FIRST_IMAGE',
  PROPERTY_FIRST_OWNER: 'enums:emailPlaceholder.PROPERTY_FIRST_OWNER',
  LEAD_ASSIGNED_AGENT_FIRST_NAME: 'enums:emailPlaceholder.LEAD_ASSIGNED_AGENT_FIRST_NAME',
  LEAD_ASSIGNED_AGENT_LAST_NAME: 'enums:emailPlaceholder.LEAD_ASSIGNED_AGENT_LAST_NAME',
  LEAD_CREATED_DT: 'enums:emailPlaceholder.LEAD_CREATED_DT',
  LEAD_CUSTOMER_MESSAGE: 'enums:emailPlaceholder.LEAD_CUSTOMER_MESSAGE',
  LEAD_INTENT_TYPE: 'enums:emailPlaceholder.LEAD_INTENT_TYPE',
  LEAD_LINK: 'enums:emailPlaceholder.LEAD_LINK',
  LEAD_PROPERTY_FULL_ADDRESS: 'enums:emailPlaceholder.LEAD_PROPERTY_FULL_ADDRESS',
  LEAD_PROPERTY_CITY: 'enums:emailPlaceholder.LEAD_PROPERTY_CITY',
  LEAD_PROPERTY_COUNTRY: 'enums:emailPlaceholder.LEAD_PROPERTY_COUNTRY',
  LEAD_PERSONAL_NOTE: 'enums:emailPlaceholder.LEAD_PERSONAL_NOTE',
  LEAD_PROPERTY_POSTAL_CODE: 'enums:emailPlaceholder.LEAD_PROPERTY_POSTAL_CODE',
  LEAD_PROPERTY_PRICE: 'enums:emailPlaceholder.LEAD_PROPERTY_PRICE',
  LEAD_PROPERTY_STREET_NAME: 'enums:emailPlaceholder.LEAD_PROPERTY_STREET_NAME',
  LEAD_PROPERTY_STREET_NUMBER: 'enums:emailPlaceholder.LEAD_PROPERTY_STREET_NUMBER',
  LEAD_PROPERTY_TYPE: 'enums:emailPlaceholder.LEAD_PROPERTY_TYPE',
  LEAD_SOURCE: 'enums:emailPlaceholder.LEAD_SOURCE',
  LEAD_ASSIGNED_AGENT_MOBILE_PHONE_NUMBER: 'enums:emailPlaceholder.LEAD_ASSIGNED_AGENT_MOBILE_PHONE_NUMBER',
  LEAD_ASSIGNED_AGENT_OFFICE_PHONE_NUMBER: 'enums:emailPlaceholder.LEAD_ASSIGNED_AGENT_OFFICE_PHONE_NUMBER',
  LEAD_ASSIGNED_AGENT_EMAIL: 'enums:emailPlaceholder.LEAD_ASSIGNED_AGENT_EMAIL',
  AGENT_EMAIL: 'enums:emailPlaceholder.AGENT_EMAIL',
  AGENT_FIRST_NAME: 'enums:emailPlaceholder.AGENT_FIRST_NAME',
  AGENT_LAST_NAME: 'enums:emailPlaceholder.AGENT_LAST_NAME',
  AGENT_JOB_TITLE: 'enums:emailPlaceholder.AGENT_JOB_TITLE',
  AGENT_MOBILE_PHONE_NUMBER: 'enums:emailPlaceholder.AGENT_MOBILE_PHONE_NUMBER',
  AGENT_OFFICE_PHONE_NUMBER: 'enums:emailPlaceholder.AGENT_OFFICE_PHONE_NUMBER',
  AGENT_SALUTATION: 'enums:emailPlaceholder.AGENT_SALUTATION',
  AGENT_LICENSE_ID: 'enums:emailPlaceholder.AGENT_LICENSE_ID',
  PROPERTY_FIRST_EXPOSEE_URL: 'enums:emailPlaceholder.PROPERTY_FIRST_EXPOSEE_URL',
  PROPERTY_SECOND_EXPOSEE_URL: 'enums:emailPlaceholder.PROPERTY_SECOND_EXPOSEE_URL',
  PROPERTY_THIRD_EXPOSEE_URL: 'enums:emailPlaceholder.PROPERTY_THIRD_EXPOSEE_URL',
  LEAD_PROPERTY_LIVING_AREA: 'enums:emailPlaceholder.LEAD_PROPERTY_LIVING_AREA',
  REFERRAL_LEAD_LINK: 'enums:emailPlaceholder.REFERRAL_LEAD_LINK',
  REFERRAL_REJECTION_REASON: 'enums:emailPlaceholder.REFERRAL_REJECTION_REASON',
  REFERRAL_AGENT_NOTE: 'enums:emailPlaceholder.REFERRAL_AGENT_NOTE',
  REFERRAL_RECEIVING_SHOP_NAME: 'enums:emailPlaceholder.REFERRAL_RECEIVING_SHOP_NAME',
  REFERRAL_RECEIVING_SHOP_LEGAL_ENTITY: 'enums:emailPlaceholder.REFERRAL_RECEIVING_SHOP_LEGAL_ENTITY',
  REFERRAL_SENDING_SHOP_NAME: 'enums:emailPlaceholder.REFERRAL_SENDING_SHOP_NAME',
  REFERRAL_RECEIVING_AGENT_FIRST_NAME: 'enums:emailPlaceholder.REFERRAL_RECEIVING_AGENT_FIRST_NAME',
  REFERRAL_RECEIVING_AGENT_LAST_NAME: 'enums:emailPlaceholder.REFERRAL_RECEIVING_AGENT_LAST_NAME',
  REFERRAL_RECEIVING_AGENT_EMAIL: 'enums:emailPlaceholder.REFERRAL_RECEIVING_AGENT_EMAIL',
  REFERRAL_RECEIVING_AGENT_MOBILE_PHONE_NUMBER: 'enums:emailPlaceholder.REFERRAL_RECEIVING_AGENT_MOBILE_PHONE_NUMBER',
  REFERRAL_COMMISSION: 'enums:emailPlaceholder.REFERRAL_COMMISSION',
  REFERRAL_FIRST_CONDITIONAL: 'enums:emailPlaceholder.REFERRAL_FIRST_CONDITIONAL',
  REFERRAL_FIRST_RECEIVING_SHOP_LEGAL_ENTITY: 'enums:emailPlaceholder.REFERRAL_FIRST_RECEIVING_SHOP_LEGAL_ENTITY',
  REFERRAL_FIRST_RECEIVING_SHOP_NAME: 'enums:emailPlaceholder.REFERRAL_FIRST_RECEIVING_SHOP_NAME',
  REFERRAL_SECOND_CONDITIONAL: 'enums:emailPlaceholder.REFERRAL_SECOND_CONDITIONAL',
  REFERRAL_SECOND_RECEIVING_SHOP_LEGAL_ENTITY: 'enums:emailPlaceholder.REFERRAL_SECOND_RECEIVING_SHOP_LEGAL_ENTITY',
  REFERRAL_SECOND_RECEIVING_SHOP_NAME: 'enums:emailPlaceholder.REFERRAL_SECOND_RECEIVING_SHOP_NAME',
  REFERRAL_THIRD_CONDITIONAL: 'enums:emailPlaceholder.REFERRAL_THIRD_CONDITIONAL',
  REFERRAL_THIRD_RECEIVING_SHOP_LEGAL_ENTITY: 'enums:emailPlaceholder.REFERRAL_THIRD_RECEIVING_SHOP_LEGAL_ENTITY',
  REFERRAL_THIRD_RECEIVING_SHOP_NAME: 'enums:emailPlaceholder.REFERRAL_THIRD_RECEIVING_SHOP_NAME',
  RECIPIENT_FIRST_NAME: 'enums:emailPlaceholder.RECIPIENT_FIRST_NAME',
  RECIPIENT_LAST_NAME: 'enums:emailPlaceholder.RECIPIENT_LAST_NAME',
  PREMIUM_EXPOSE: 'enums:emailPlaceholder.PREMIUM_EXPOSE',
  PREMIUM_EXPOSE_SUBJECT: 'enums:emailPlaceholder.PREMIUM_EXPOSE_SUBJECT',
  LEADS_LIST: 'enums:emailPlaceholder.LEADS_LIST',
} as const satisfies Record<EmailPlaceholderTypeEnum, string>;

export const assetConditionTranslation = {
  AVERAGE: 'enums:assetCondition.AVERAGE',
  GOOD: 'enums:assetCondition.GOOD',
  NEEDS_REHABILITATION: 'enums:assetCondition.NEEDS_REHABILITATION',
  NEEDS_RENOVATION: 'enums:assetCondition.NEEDS_RENOVATION',
  OTHER: 'enums:assetCondition.OTHER',
  PARTLY_REHABILITATED: 'enums:assetCondition.PARTLY_REHABILITATED',
  REHABILITATION_DONE: 'enums:assetCondition.REHABILITATION_DONE',
  TOP: 'enums:assetCondition.TOP',
  VERY_GOOD: 'enums:assetCondition.VERY_GOOD',
} as const satisfies Record<AssetConditionEnum, string>;

export const priorityTranslation = {
  HIGH: 'enums:priority.HIGH',
  MEDIUM: 'enums:priority.MEDIUM',
  LOW: 'enums:priority.LOW',
} as const satisfies Record<PriorityEnum, string>;

export const activityPriorityTranslation = {
  HIGH: 'enums:activity.priority.HIGH',
  MEDIUM: 'enums:activity.priority.MEDIUM',
  LOW: 'enums:activity.priority.LOW',
} as const satisfies Record<PriorityEnum, string>;

export const contactTimeTranslation = {
  AFTERNOON: 'enums:contactTime.AFTERNOON',
  ANYTIME: 'enums:contactTime.ANYTIME',
  EVENING: 'enums:contactTime.EVENING',
  MORNING: 'enums:contactTime.MORNING',
  NOON: 'enums:contactTime.NOON',
  UNDEFINED: 'enums:contactTime.UNDEFINED',
} as const satisfies Record<ContactTimeEnum, string>;

export const leadStatusTranslation = {
  ACTIVE: 'enums:leadStatus.ACTIVE',
  EXPIRED: 'enums:leadStatus.EXPIRED',
  INACTIVE: 'enums:leadStatus.INACTIVE',
  INVALID: 'enums:leadStatus.INVALID',
  CLOSED: 'enums:leadStatus.CLOSED',
} as const satisfies Record<LeadStatusEnum, string>;

export const salutationTranslation = {
  MR: 'enums:salutation.MR',
  MRS: 'enums:salutation.MRS',
  OTHER: 'enums:salutation.OTHER',
} as const satisfies Record<SalutationEnum, string>;

export const householdTypeTranslation = {
  ONE_PERSON: 'enums:householdType.ONE_PERSON',
  TWO_ADULTS: 'enums:householdType.TWO_ADULTS',
  FAMILY: 'enums:householdType.FAMILY',
  FLATSHARE: 'enums:householdType.FLATSHARE',
} as const satisfies Record<HouseholdTypeEnum, string>;

export const professionTypeTranslation = {
  EMPLOYEE: 'enums:professionType.EMPLOYEE',
  WORKER: 'enums:professionType.WORKER',
  SELF_EMPLOYED: 'enums:professionType.SELF_EMPLOYED',
  OFFICIAL: 'enums:professionType.OFFICIAL',
  TRAINEE: 'enums:professionType.TRAINEE',
  STUDENT: 'enums:professionType.STUDENT',
  DOCTORAL_STUDENT: 'enums:professionType.DOCTORAL_STUDENT',
  HOMEMAKER: 'enums:professionType.HOMEMAKER',
  JOB_SEEKER: 'enums:professionType.JOB_SEEKER',
  RETIREE: 'enums:professionType.RETIREE',
  OTHER: 'enums:professionType.OTHER',
} as const satisfies Record<ProfessionTypeEnum, string>;

export const assetSubTypeTranslation = {
  AGRICULTURE_LAND: 'enums:assetSubType.AGRICULTURE_LAND',
  ATTIC_APARTMENT: 'enums:assetSubType.ATTIC_APARTMENT',
  BUILDING_LAND: 'enums:assetSubType.BUILDING_LAND',
  BUNGALOW: 'enums:assetSubType.BUNGALOW',
  COUNTRY_HOUSE: 'enums:assetSubType.COUNTRY_HOUSE',
  DETACHED_HOUSE: 'enums:assetSubType.DETACHED_HOUSE',
  DEVELOPED_LAND: 'enums:assetSubType.DEVELOPED_LAND',
  DUPLEX: 'enums:assetSubType.DUPLEX',
  FLAT: 'enums:assetSubType.FLAT',
  FOREST: 'enums:assetSubType.FOREST',
  GROUND_FLOOR_APARTMENT: 'enums:assetSubType.GROUND_FLOOR_APARTMENT',
  HOTEL_APARTMENT: 'enums:assetSubType.HOTEL_APARTMENT',
  HUNTING_GROUND: 'enums:assetSubType.HUNTING_GROUND',
  INVESTMENT_HOUSE: 'enums:assetSubType.INVESTMENT_HOUSE',
  LAKESIDE_LAND: 'enums:assetSubType.LAKESIDE_LAND',
  LAND_IN_BUILDING_LEASE: 'enums:assetSubType.LAND_IN_BUILDING_LEASE',
  LOFT: 'enums:assetSubType.LOFT',
  MOORING: 'enums:assetSubType.MOORING',
  MULTI_FAMILY_HOUSE: 'enums:assetSubType.MULTI_FAMILY_HOUSE',
  OLD_BUILDING_APARTMENT: 'enums:assetSubType.OLD_BUILDING_APARTMENT',
  OTHER_APARTMENT: 'enums:assetSubType.OTHER_APARTMENT',
  OTHER_HOUSE: 'enums:assetSubType.OTHER_HOUSE',
  OTHER_LAND: 'enums:assetSubType.OTHER_LAND',
  PADDOCK: 'enums:assetSubType.PADDOCK',
  PARKING: 'enums:assetSubType.PARKING',
  PASTURE: 'enums:assetSubType.PASTURE',
  PENTHOUSE: 'enums:assetSubType.PENTHOUSE',
  ROW_HOUSE: 'enums:assetSubType.ROW_HOUSE',
  SEASIDE_LAND: 'enums:assetSubType.SEASIDE_LAND',
  SEMI_DETACHED_HOUSE: 'enums:assetSubType.SEMI_DETACHED_HOUSE',
  SPECIAL_PROPERTY: 'enums:assetSubType.SPECIAL_PROPERTY',
  STUDIO: 'enums:assetSubType.STUDIO',
  TOWNHOUSE: 'enums:assetSubType.TOWNHOUSE',
  VILLA: 'enums:assetSubType.VILLA',
  WINE_LAND: 'enums:assetSubType.WINE_LAND',
} as const satisfies Record<AssetSubTypeEnum, string>;

export const assetVariationTranslation = {
  MID_ROW_HOUSE: 'enums:assetVariation.MID_ROW_HOUSE',
  END_OF_ROW_HOUSE: 'enums:assetVariation.END_OF_ROW_HOUSE',
  FARMHOUSE: 'enums:assetVariation.FARMHOUSE',
  FARM: 'enums:assetVariation.FARM',
  COTTAGE: 'enums:assetVariation.COTTAGE',
  CHALET: 'enums:assetVariation.CHALET',
  FINCA: 'enums:assetVariation.FINCA',
  CASTLE: 'enums:assetVariation.CASTLE',
  HOUSEBOAT: 'enums:assetVariation.HOUSEBOAT',
  MANOR: 'enums:assetVariation.MANOR',
} as const satisfies Record<AssetVariationEnum, string>;

export const contactRelationshipTypeTranslation = {
  IS_EMPLOYER_OF: 'enums:contactRelationshipType.IS_EMPLOYER_OF',
  IS_EMPLOYED_BY: 'enums:contactRelationshipType.IS_EMPLOYED_BY',
  IS_CO_OWNER_OF: 'enums:contactRelationshipType.IS_CO_OWNER_OF',
  IS_CO_OWNED_BY: 'enums:contactRelationshipType.IS_CO_OWNED_BY',
  IS_AUTHORIZING_OF: 'enums:contactRelationshipType.IS_AUTHORIZING_OF',
  IS_AUTHORIZED_BY: 'enums:contactRelationshipType.IS_AUTHORIZED_BY',
  KNOWS: 'enums:contactRelationshipType.KNOWS',
  IS_RELATIVE_FROM: 'enums:contactRelationshipType.IS_RELATIVE_FROM',
  IS_BUSINESS_CONTACT_FROM: 'enums:contactRelationshipType.IS_BUSINESS_CONTACT_FROM',
  LIVES_TOGETHER_WITH: 'enums:contactRelationshipType.LIVES_TOGETHER_WITH',
  HAS_DISTURBED_RELATION_TO: 'enums:contactRelationshipType.HAS_DISTURBED_RELATION_TO',
  RECOMMENDED_BY: 'enums:contactRelationshipType.RECOMMENDED_BY',
  RECOMMEND_TO: 'enums:contactRelationshipType.RECOMMEND_TO',
  IS_THE_SAME_CONTACT_AS: 'enums:contactRelationshipType.IS_THE_SAME_CONTACT_AS',
  PARTNER_OR_SPOUSE: 'enums:contactRelationshipType.PARTNER_OR_SPOUSE',
} as const satisfies Record<ContactRelationTypeEnum, string>;

export const phoneNumberTypeTranslation = {
  FAX: 'enums:phoneNumberType.FAX',
  UNDEFINED: 'enums:phoneNumberType.UNDEFINED',
  PHONE_WORK: 'enums:phoneNumberType.PHONE_WORK',
  PHONE_PRIVATE: 'enums:phoneNumberType.PHONE_PRIVATE',
  MOBILE_PRIVATE: 'enums:phoneNumberType.MOBILE_PRIVATE',
  MOBILE_WORK: 'enums:phoneNumberType.MOBILE_WORK',
} as const satisfies Record<PhoneNumberTypeEnum, string>;

export const emailAddressTypeTranslation = {
  PRIVATE: 'enums:emailAddressType.PRIVATE',
  PUBLIC: 'enums:emailAddressType.PUBLIC',
  WORK: 'enums:emailAddressType.WORK',
  UNDEFINED: 'enums:emailAddressType.UNDEFINED',
} as const satisfies Record<EmailAddressTypeEnum, string>;

export const addressTypeTranslation = {
  BUSINESS: 'enums:addressType.BUSINESS',
  HOLIDAY: 'enums:addressType.HOLIDAY',
  PRIVATE: 'enums:addressType.PRIVATE',
  SECOND_IN_FOREIGN_COUNTRY: 'enums:addressType.SECOND_IN_FOREIGN_COUNTRY',
  SECOND_IN_HOME_COUNTRY: 'enums:addressType.SECOND_IN_HOME_COUNTRY',
  SECRET: 'enums:addressType.SECRET',
  UNDEFINED: 'enums:addressType.UNDEFINED',
  WEEKEND: 'enums:addressType.WEEKEND',
} as const satisfies Record<AddressTypeEnum, string>;

export const referralNotPossibleReasonTranslation = {
  BLACKLIST: 'enums:referralNotPossibleReason.BLACKLIST',
  CUSTOMER_DOES_NOT_RESPOND: 'enums:referralNotPossibleReason.CUSTOMER_DOES_NOT_RESPOND',
  CUSTOMER_IN_CONTACT_WITH_SHOP: 'enums:referralNotPossibleReason.CUSTOMER_IN_CONTACT_WITH_SHOP',
  CUSTOMER_REVOKED_REQUEST: 'enums:referralNotPossibleReason.CUSTOMER_REVOKED_REQUEST',
  FICTITIOUS_REQUEST: 'enums:referralNotPossibleReason.FICTITIOUS_REQUEST',
  INVALID_CONTACT_INFO: 'enums:referralNotPossibleReason.INVALID_CONTACT_INFO',
  NO_SHOP_IN_THIS_AREA: 'enums:referralNotPossibleReason.NO_SHOP_IN_THIS_AREA',
  REFERRAL_REJECTED: 'enums:referralNotPossibleReason.REFERRAL_REJECTED',
} as const satisfies Record<ReferralNotPossibleReasonEnum, string>;

export const zeroCommissionReasonTranslation = {
  DUPLICATE: 'enums:zeroCommissionReason.DUPLICATE',
  FARMING: 'enums:zeroCommissionReason.FARMING',
  OTHERS: 'enums:zeroCommissionReason.OTHERS',
  HQ_PROMOTION: 'enums:zeroCommissionReason.HQ_PROMOTION',
  OUTSIDE_LICENSE_AREA: 'enums:zeroCommissionReason.OUTSIDE_LICENSE_AREA',
  RECOMMENDATION: 'enums:zeroCommissionReason.RECOMMENDATION',
} as const satisfies Record<ZeroCommissionReasonEnum, string>;

export const contactIntentTypeTranslation = {
  BUYER: 'contact:intent.type.buyer',
  SELLER: 'contact:intent.type.seller',
  RENT_OUT: 'contact:intent.type.rentOut',
  TO_RENT: 'contact:intent.type.toRent',
} as const satisfies Record<IntentTabEnum, string>;

export const leadIntentTypeTranslation = {
  BUYER: 'lead:intentType.buyer',
  SELLER: 'lead:intentType.seller',
  RENT_OUT: 'lead:intentType.rentOut',
  TO_RENT: 'lead:intentType.toRent',
} as const satisfies Record<IntentTabEnum, string>;

export const shopExpertiseTranslation = {
  RESIDENTIAL_SALES: 'enums:shopExpertise.RESIDENTIAL_SALES',
  INVESTMENT_PROPERTIES: 'enums:shopExpertise.INVESTMENT_PROPERTIES',
  LUXURY_HOMES: 'enums:shopExpertise.LUXURY_HOMES',
  VILLAS: 'enums:shopExpertise.VILLAS',
  COMMERCIAL_SPACE: 'enums:shopExpertise.COMMERCIAL_SPACE',
  INDUSTRY: 'enums:shopExpertise.INDUSTRY',
  GASTRONOMY: 'enums:shopExpertise.GASTRONOMY',
  OFFICE: 'enums:shopExpertise.OFFICE',
} as const satisfies Record<ShopExpertiseEnum, string>;

export const consentStatusTranslation = {
  WAITING: 'enums:consentStatus.WAITING',
  CONSENTED: 'enums:consentStatus.CONSENTED',
  REJECTED: 'enums:consentStatus.REJECTED',
  REVOKED: 'enums:consentStatus.REVOKED',
  EXPIRED: 'enums:consentStatus.EXPIRED',
} as const satisfies Record<ConsentStatusEnum, string>;

export const consentSourceTranslation = {
  CONSENTED_OFFLINE: 'enums:consentSource.CONSENTED_OFFLINE',
  CONSENTED_ONLINE: 'enums:consentSource.CONSENTED_ONLINE',
  EXPLICITLY_CONSENTED_TO: 'enums:consentSource.EXPLICITLY_CONSENTED_TO',
} as const satisfies Record<ConsentSourceEnum, string>;

export const intentCategoryAgents = {
  SELLER: 'contact:contactDetails.sellerAgent',
  RENTAL: 'contact:contactDetails.rentalAgent',
  BUYER: 'contact:contactDetails.buyerAgent',
} as const satisfies Record<IntentCategoryEnum, string>;

export const intentTabAgents = {
  SELLER: 'contact:contactDetails.sellerAgent',
  RENT_OUT: 'contact:contactDetails.rentalAgent',
  TO_RENT: 'contact:contactDetails.rentalAgent',
  BUYER: 'contact:contactDetails.buyerAgent',
} as const satisfies Record<IntentTabEnum, string>;

export const go3LeadStatusTranslation = {
  ACTIVE: 'enums:go3.leadStatus.ACTIVE',
  INACTIVE: 'enums:go3.leadStatus.INACTIVE',
  PENDING: 'enums:go3.leadStatus.PENDING',
  OFFERSHOW: 'enums:go3.leadStatus.OFFERSHOW',
  OFFERNOSHOW: 'enums:go3.leadStatus.OFFERNOSHOW',
  SOLDBYEV: 'enums:go3.leadStatus.SOLDBYEV',
  SOLDBYOTHERS: 'enums:go3.leadStatus.SOLDBYOTHERS',
  WITHDRAWN: 'enums:go3.leadStatus.WITHDRAWN',
  EXPIRED: 'enums:go3.leadStatus.EXPIRED',
  CANCELLED: 'enums:go3.leadStatus.CANCELLED',
  COMPPROSPECT: 'enums:go3.leadStatus.COMPPROSPECT',
  LETBYEV: 'enums:go3.leadStatus.LETBYEV',
  LETBYOTHERS: 'enums:go3.leadStatus.LETBYOTHERS',
} as const satisfies Record<Go3LeadStatus, string>;

export const callOutcome = {
  TALKED: 'enums:callOutcome.TALKED',
  LEFT_MESSAGE: 'enums:callOutcome.LEFT_MESSAGE',
  BUSY: 'enums:callOutcome.BUSY',
  NO_ANSWER: 'enums:callOutcome.NO_ANSWER',
  FAILED: 'enums:callOutcome.FAILED',
  WRONG_NUMBER: 'enums:callOutcome.WRONG_NUMBER',
} as const satisfies Record<CallOutcome, string>;

export const intentCategoryTabs = {
  SELLER: 'contact:tab.seller',
  RENT_OUT: 'contact:tab.rentOut',
  TO_RENT: 'contact:tab.toRent',
  BUYER: 'contact:tab.buyer',
} as const satisfies Record<IntentTabEnum, string>;

export const enumCallStatusTranslation = {
  MISSED: 'communication:calls.callType.missed',
  INCOMING: 'communication:calls.callType.incoming',
  OUTGOING: 'communication:calls.callType.outgoing',
} as const satisfies Record<CallType, string>;

export const baseActivityCategory = {
  APPOINTMENT: 'enums:baseActivityCategory.APPOINTMENT',
  CALL: 'enums:baseActivityCategory.CALL',
  CONSENT: 'enums:baseActivityCategory.CONSENT',
  CONTACT: 'enums:baseActivityCategory.CONTACT',
  DOCUMENT: 'enums:baseActivityCategory.DOCUMENT',
  EMAIL: 'enums:baseActivityCategory.EMAIL',
  LEAD: 'enums:baseActivityCategory.LEAD',
  TASK: 'enums:baseActivityCategory.TASK',
  SHARED: 'enums:baseActivityCategory.SHARED',
  EVA_EXPORT: 'enums:baseActivityCategory.EVA_EXPORT',
} as const satisfies Record<BaseActivityCategoryEnum, string>;

export const appointmentTypeTranslation = {
  HANDOVER: 'enums:appointmentType.HANDOVER',
  LISTING_PRESENTATION: 'enums:appointmentType.LISTING_PRESENTATION',
  MISCELLANEOUS: 'enums:appointmentType.MISCELLANEOUS',
  NOTARY: 'enums:appointmentType.NOTARY',
  PRICE_MODIFICATION: 'enums:appointmentType.PRICE_MODIFICATION',
  PRICE_REDUCTION: 'enums:appointmentType.PRICE_REDUCTION',
  SEATRIAL: 'enums:appointmentType.SEATRIAL',
  OPEN_HOUSE: 'enums:appointmentType.OPEN_HOUSE',
  VALUATION: 'enums:appointmentType.VALUATION',
  VIEWING: 'enums:appointmentType.VIEWING',
} as const satisfies Record<AppointmentTypeEnum, string>;

export const taskTypeTranslation = {
  ACTIVE_BUYER_CONTACT: 'enums:taskType.ACTIVE_BUYER_CONTACT',
  ACQUISITION: 'enums:taskType.ACQUISITION',
  ACTIVITY_REPORT: 'enums:taskType.ACTIVITY_REPORT',
  ACTIVE_SELLER_CONTACT: 'enums:taskType.ACTIVE_SELLER_CONTACT',
  INCOMING_BUYER_SELLER_CONTACT: 'enums:taskType.INCOMING_BUYER_SELLER_CONTACT',
  FOLLOW_UP: 'enums:taskType.FOLLOWUP',
  MISCELLANEOUS: 'enums:taskType.MISCELLANEOUS',
  NEWSLETTER: 'enums:taskType.NEWSLETTER',
  OFFER: 'enums:taskType.OFFER',
  OWNERSHIP_ANNIVERSARY: 'enums:taskType.OWNERSHIP_ANNIVERSARY',
  PLACE_ADVERTISEMENT: 'enums:taskType.PLACE_ADVERTISEMENT',
  PRICE_REDUCTION: 'enums:taskType.PRICE_REDUCTION',
  TODO: 'enums:taskType.TODO',
  INCOMING_CALL: 'enums:taskType.INCOMING_CALL',
  MAKE_AN_APPOINTMENT: 'enums:taskType.MAKE_AN_APPOINTMENT',
  MARKET_REPORT: 'enums:taskType.MARKET_REPORT',
  NOTICE: 'enums:taskType.NOTICE',
  OUTGOING_CALL: 'enums:taskType.OUTGOING_CALL',
  EXPOSE_MANUAL_SHARE: 'enums:taskType.EXPOSE_MANUAL_SHARE',
} as const satisfies Record<TaskTypeEnum, string>;

export const taskTitleTranslation = {
  ACTIVE_BUYER_CONTACT: 'enums:dashboard.task.title.ACTIVE_BUYER_CONTACT',
  ACQUISITION: 'enums:dashboard.task.title.ACQUISITION',
  ACTIVITY_REPORT: 'enums:dashboard.task.title.ACTIVITY_REPORT',
  ACTIVE_SELLER_CONTACT: 'enums:dashboard.task.title.ACTIVE_SELLER_CONTACT',
  INCOMING_BUYER_SELLER_CONTACT: 'enums:dashboard.task.title.INCOMING_BUYER_SELLER_CONTACT',
  FOLLOW_UP: 'enums:dashboard.task.title.FOLLOW_UP',
  MISCELLANEOUS: 'enums:dashboard.task.title.MISCELLANEOUS',
  NEWSLETTER: 'enums:dashboard.task.title.NEWSLETTER',
  OFFER: 'enums:dashboard.task.title.OFFER',
  OWNERSHIP_ANNIVERSARY: 'enums:dashboard.task.title.OWNERSHIP_ANNIVERSARY',
  PLACE_ADVERTISEMENT: 'enums:dashboard.task.title.PLACE_ADVERTISEMENT',
  PRICE_REDUCTION: 'enums:dashboard.task.title.PRICE_REDUCTION',
  TODO: 'enums:dashboard.task.title.TODO',
  INCOMING_CALL: 'enums:dashboard.task.title.INCOMING_CALL',
  MAKE_AN_APPOINTMENT: 'enums:dashboard.task.title.MAKE_AN_APPOINTMENT',
  MARKET_REPORT: 'enums:dashboard.task.title.MARKET_REPORT',
  NOTICE: 'enums:dashboard.task.title.NOTICE',
  OUTGOING_CALL: 'enums:dashboard.task.title.OUTGOING_CALL',
  EXPOSE_MANUAL_SHARE: 'enums:dashboard.task.title.EXPOSE_MANUAL_SHARE',
} as const satisfies Record<TaskTypeEnum, string>;

// Presently there is no shared type enum to resolve this properly
//export const sharedTypeTranslation = {
//  EXPOSE: 'enums:sharedType.EXPOSE',
//}; as const satisfies Record<SharedTypeEnum, string>;

export const taskAppointmentStatusTranslation = {
  OPEN: 'enums:taskAppointmentStatus.OPEN',
  COMPLETED: 'enums:taskAppointmentStatus.COMPLETED',
  CANCELLED: 'enums:taskAppointmentStatus.CANCELLED',
} as const satisfies Record<TaskAppointmentStatusEnum, string>;

export const privacyLevelTranslation = {
  SHOP: 'enums:privacyLevel.SHOP',
  TEAM: 'enums:privacyLevel.TEAM',
  PERSONAL: 'enums:privacyLevel.PERSONAL',
  LICENSE_PARTNER: 'enums:privacyLevel.LICENSE_PARTNER',
} as const satisfies Record<PrivacyLevelEnum, string>;

export const entityTypeTranslation = {
  AGENT: 'enums:entityType.AGENT',
  APPOINTMENT: 'enums:entityType.APPOINTMENT',
  APPOINTMENT_ACTIVITY: 'enums:entityType.APPOINTMENT_ACTIVITY',
  CHANGE_HISTORY: 'enums:entityType.CHANGE_HISTORY',
  CONTACT: 'enums:entityType.CONTACT',
  CONTACT_RELATIONSHIP: 'enums:entityType.CONTACT_RELATIONSHIP',
  CONTACT_TAG: 'enums:entityType.CONTACT_TAG',
  DOCUMENT_REFERENCE: 'enums:entityType.DOCUMENT_REFERENCE',
  EMAIL: 'enums:entityType.EMAIL',
  EMAIL_ACTIVITY: 'enums:entityType.EMAIL',
  LEAD: 'enums:entityType.LEAD',
  PRINTED_DOCUMENT: 'enums:entityType.PRINTED_DOCUMENT',
  TAG: 'enums:entityType.TAG',
  TASK_ACTIVITY: 'enums:entityType.TASK_ACTIVITY',
  VIEWING_APPOINTMENT_ACTIVITY: 'enums:entityType.VIEWING_APPOINTMENT_ACTIVITY',
  CONSENT: 'enums:entityType.CONSENT',
} as const satisfies Record<EntityTypeEnum, string>;

export const matchingStateTranslation = {
  NOT_INTERESTED: 'lead:matching.matchingState.notInterested',
  NOT_PROPOSED: 'lead:matching.matchingState.notProposed',
  PROPOSED: 'lead:matching.matchingState.proposed',
} as const satisfies Record<PropertyEngineMatchingState, string>;

export const contactTypeTranslation = {
  CONTACT_PERSON: 'contact:contactType.CONTACT_PERSON',
  SERVICE_CONTACT: 'contact:contactType.SERVICE_CONTACT',
  TENANT: 'contact:contactType.TENANT',
  UNDEFINED: 'contact:contactType.UNDEFINED',
} as const satisfies Record<ContactTypeEnum, string>;

export const documentStatusTranslation = {
  OPEN: 'activities:exposeSharedActivity.status.OPEN',
  COMPLETED: 'activities:exposeSharedActivity.status.COMPLETED',
  CANCELLED: 'activities:exposeSharedActivity.status.CANCELLED',
} as const satisfies Record<string, string>;

export const kycRiskLevelTranslation = {
  LOW: 'contact:kyc.riskLevel.LOW',
  MIDDLE: 'contact:kyc.riskLevel.MIDDLE',
  HIGH: 'contact:kyc.riskLevel.HIGH',
} as const satisfies Record<KycRiskLevelEnum, string>;

export const marketingTypeTranslation = {
  SALE: 'enums:marketingType.SALE',
  RENT: 'enums:marketingType.RENT',
  DEVELOPMENT: 'enums:marketingType.DEVELOPMENT',
} as const satisfies Record<MarketingTypeEnum, string>;

export const consentTypeTranslation = {
  MARKETING: 'enums:consentType.MARKETING',
  FINANCE_DATA_PROCESSING: 'enums:consentType.FINANCE_DATA_PROCESSING',
  MATCHING_PROPERTY_RECOMMENDATION: 'enums:consentType.MATCHING_PROPERTY_RECOMMENDATION',
  VISIT_CONFIRMATION: 'enums:consentType.VISIT_CONFIRMATION',
  CONTACTING: 'enums:consentType.CONTACTING',
  REFERRALS: 'enums:consentType.REFERRALS',
} as const satisfies Record<ConsentTypeEnum, string>;

export const propertyEngineListingStatusTranslation = {
  ACTIVE: 'enums:propertyEngineListingStatus.ACTIVE',
  CANCELLED: 'enums:propertyEngineListingStatus.CANCELLED',
  CLOSED_BY_EV: 'enums:propertyEngineListingStatus.CLOSED_BY_EV',
  CLOSED_BY_OTHERS: 'enums:propertyEngineListingStatus.CLOSED_BY_OTHERS',
  EXPIRED: 'enums:propertyEngineListingStatus.EXPIRED',
  INACTIVE: 'enums:propertyEngineListingStatus.INACTIVE',
  OFFER_NO_SHOW: 'enums:propertyEngineListingStatus.OFFER_NO_SHOW',
  OFFER_SHOW: 'enums:propertyEngineListingStatus.OFFER_SHOW',
  PENDING: 'enums:propertyEngineListingStatus.PENDING',
  STREET_INDEX: 'enums:propertyEngineListingStatus.STREET_INDEX',
  WITHDRAWN: 'enums:propertyEngineListingStatus.WITHDRAWN',
  UNDEFINED: 'enums:propertyEngineListingStatus.UNDEFINED',
} as const satisfies Record<PropertyEngineListingStatus, string>;

export const shopStatusTranslation = {
  BLACKLISTED: 'enums:shopStatus.BLACKLISTED',
  BLOCKED: 'enums:shopStatus.BLOCKED',
  CLOSED: 'enums:shopStatus.CLOSED',
  OPENED: 'enums:shopStatus.OPENED',
  PLANNED: 'enums:shopStatus.PLANNED',
} as const satisfies Record<ShopStatusEnum, string>;

export const exposeTemplateTypeTranslation = {
  EXPOSE: 'enums:exposeTemplateType.EXPOSE',
  PRICE_REDUCTION: 'enums:exposeTemplateType.PRICE_REDUCTION',
  PREMIUM_EXPOSE_MAIL: 'enums:exposeTemplateType.PREMIUM_EXPOSE_MAIL',
} as const satisfies Record<ExposeTemplateType, string>;
