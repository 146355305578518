import { useJwtToken } from '@ev/eva-container-api';
import { useQuery } from '@tanstack/react-query';
import { graphqlClient } from 'api/graphql/graphql-client';
import { agentQuery } from 'api/graphql/hooks/useAgents';

export const useCurrentAgent = () => {
  const { jwtToken } = useJwtToken();
  const agentId = jwtToken?.sub;

  const { data: currentAgent, ...rest } = useQuery({
    queryKey: ['agent', 'useCurrentAgent', agentId],
    queryFn: async () => (await graphqlClient.request(agentQuery, { where: { id: { _eq: agentId } } })).dbAgent[0],
    enabled: !!agentId,
    staleTime: 300_000,
    refetchInterval: 300_000,
  });
  return { currentAgent, ...rest, defaultAgentEmail: currentAgent?.nylasEmailIntegrations[0]?.email };
};

export const useCurrentAgentId = () => {
  const { jwtToken } = useJwtToken();
  return jwtToken.sub;
};
