import { Stack, Typography } from '@mui/material';
import { EVAlert } from 'components/general/EVAlert/EVAlert';
import { useFormContext } from 'react-hook-form';
import { UNDEFINED_OPTION_VALUE } from 'util/emptyDataUtils';
import { useTranslation } from 'util/i18next';
import { ContactDetailsFormData } from 'util/schemas/contactDetailsSchema';
import { joinWithComma } from 'util/stringUtils';

const kycRequiredFields = ['firstName', 'lastName', 'birthDate', 'placeOfBirth', 'nationality', 'idNumber'] as const;

const addressRequiredFields = ['streetName', 'streetNumber', 'city', 'postalCode', 'countryCode'] as const;

export function KycMissingFieldsAlert({ kycAddressPlaceId }: { kycAddressPlaceId?: string }) {
  const { t } = useTranslation(['contact']);

  const { getValues } = useFormContext<ContactDetailsFormData>();

  const kycAddress = getValues('addresses').find((address) => address?.address?.placeId === kycAddressPlaceId);

  const kycMissingFields = kycRequiredFields.filter((field) => {
    const fieldValue = getValues(field);
    return !fieldValue || fieldValue === UNDEFINED_OPTION_VALUE;
  });

  const addressMissingFields = addressRequiredFields.filter((field) => {
    const fieldValue = kycAddress?.address?.[field];
    return !fieldValue || fieldValue === UNDEFINED_OPTION_VALUE;
  });

  const kycMissingFieldText = joinWithComma(
    kycMissingFields.map((field) => t(`contact:drawer.kycWarning.missingField.${field}`)),
  );

  const addressMissingFieldText = joinWithComma(
    addressMissingFields.map((field) => t(`contact:drawer.kycWarning.missingField.address.${field}`)),
  );

  if (!kycMissingFieldText && !addressMissingFieldText) {
    return null;
  }

  return (
    <EVAlert severity="warning" sx={{ mb: 3 }} fullWidth>
      <Stack gap={1}>
        <Typography variant="body3" fontWeight="bold">
          {t('contact:drawer.kycWarning.title')}
        </Typography>
        {kycMissingFieldText && (
          <Typography variant="body3">
            {t('contact:drawer.kycWarning.message', {
              missingFields: kycMissingFieldText,
            })}
          </Typography>
        )}
        {addressMissingFieldText && (
          <Typography variant="body3">
            {t('contact:drawer.kycWarning.message.address', {
              missingFields: addressMissingFieldText,
            })}
          </Typography>
        )}
      </Stack>
    </EVAlert>
  );
}
