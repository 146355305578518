import TextField, { TextFieldProps } from '@mui/material/TextField';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { useFormatNumber } from 'util/useFormatNumber';

type NumberFieldProps = Omit<NumericFormatProps<TextFieldProps>, 'onChange'> & {
  onChange: (value: string) => void;
};

export function NumberField({ error, label, value, inputRef, onChange, helperText, ...rest }: NumberFieldProps) {
  const { localeFormattingSettings } = useFormatNumber();

  return (
    <NumericFormat
      customInput={TextField}
      thousandSeparator={localeFormattingSettings.thousandSeparator}
      valueIsNumericString
      error={error}
      label={label}
      value={value}
      inputRef={inputRef}
      onValueChange={({ value }) => {
        onChange(value);
      }}
      helperText={helperText}
      {...rest}
    />
  );
}

type NumberFieldWithControllerProps<T extends FieldValues> = Omit<
  NumberFieldProps,
  'onChange' | 'value' | 'onBlur' | 'name' | 'ref'
> & {
  name: Path<T>;
  control: Control<T>;
};

export function NumberFieldWithController<T extends FieldValues>({
  name,
  control,
  error,
  helperText,
  label,
  ...restProps
}: NumberFieldWithControllerProps<T>) {
  const { localeFormattingSettings } = useFormatNumber();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...restField } }) => {
        return (
          <NumberField
            label={label}
            error={error}
            helperText={helperText}
            inputRef={ref}
            thousandSeparator={localeFormattingSettings.thousandSeparator}
            decimalSeparator={localeFormattingSettings.decimalSeparator}
            {...restProps}
            {...restField}
          />
        );
      }}
    />
  );
}
