import { CurrencyEnum } from 'api/graphql/generated/graphql';
import { ContactDetailsFormData } from 'util/schemas/contactDetailsSchema';

export function newContactDefaultValues(shopCurrency: CurrencyEnum): ContactDetailsFormData {
  return {
    _internals: {
      contact: {
        preferredAddress: 0,
        preferredEmail: 0,
        preferredPhone: 0,
      },
    },
    additionalNote: '',
    addresses: [
      {
        addressType: '',
        address: {},
        preferred: true,
        kycPreferred: false,
      },
    ],
    birthDate: null,
    placeOfBirth: undefined,
    nationality: undefined,
    idNumber: undefined,
    autoCc: false,
    company: '',
    division: '',
    emailAddresses: [
      {
        email: '',
        emailType: '',
        preferred: true,
      },
    ],
    salutation: undefined,
    title: '',
    firstName: '',
    lastName: '',
    formattedSalutation: undefined,
    isCompany: false,
    phoneNumbers: [
      {
        number: '',
        phoneType: '',
        preferred: true,
      },
    ],
    preferredLanguage: undefined,
    preferredTimes: [],
    contactRelationships: [
      {
        type: undefined,
        visibleContacts: [],
        hiddenContacts: [],
        autoCc: false,
      },
    ],
    renterDetails: {
      householdType: undefined,
      professionType: undefined,
      petOwner: undefined,
      incomeRange: {
        minimum: undefined,
        maximum: undefined,
        _currency: shopCurrency,
      },
    },
  };
}
