import {
  AddressTypeEnum,
  ContactTimeEnum,
  CountryEnum,
  EmailAddressTypeEnum,
  LanguageEnum,
  PhoneNumberTypeEnum,
  SalutationEnum,
} from 'api/graphql/generated/graphql';
import { UpdateContactRequest } from 'api/rest/models/rest/Command';
import { formatISO } from 'date-fns';
import { diffPatcher } from 'util/changeDetection';
import { contactRelationshipMapper } from 'util/mappers/contactRelationshipMapper';
import { ContactDetailsFormData } from 'util/schemas/contactDetailsSchema';

export function toUpdateContactRequest(
  initialContactFormData: ContactDetailsFormData,
  editedContactFormData: ContactDetailsFormData,
  contactId: string,
  shopId: string,
): UpdateContactRequest {
  const diff = diffPatcher.diff({ contact: initialContactFormData }, { contact: editedContactFormData });

  return {
    shopId,
    contactId,
    addresses: editedContactFormData.addresses
      .filter(
        ({ address }) =>
          address?.streetName || address?.streetNumber || address?.postalCode || address?.city || address?.countryCode,
      )
      .map(({ address, preferred, kycPreferred, addressType }) => ({
        ...address,
        addressFormat: 'FULL',
        preferred: preferred,
        kycPreferred: kycPreferred,
        addressType: addressType === '' ? 'UNDEFINED' : (addressType as AddressTypeEnum),
        countryCode: address?.countryCode === '' ? 'UNDEFINED' : (address?.countryCode as CountryEnum),
      })),
    changes: diff ? { version: '1.0', data: diff } : undefined,
    company: editedContactFormData.isCompany
      ? {
          name: editedContactFormData.company as string,
          department: editedContactFormData.division,
        }
      : undefined,
    emailAddresses: editedContactFormData.emailAddresses
      .map((email) => ({
        ...email,
        emailType: email.emailType === '' ? 'UNDEFINED' : (email.emailType as EmailAddressTypeEnum),
      }))
      .filter(({ email }) => email && email.length > 0),
    note: editedContactFormData.additionalNote,
    autoCc: editedContactFormData.autoCc,
    tags: editedContactFormData.tags?.map((tag) => tag.value),
    person: {
      firstName: editedContactFormData.firstName || undefined,
      lastName: editedContactFormData.lastName || undefined,
      salutation: (editedContactFormData.salutation as SalutationEnum) || undefined,
      title: editedContactFormData.title,
      formattedSalutation: editedContactFormData.formattedSalutation,
      birthDate: editedContactFormData.birthDate
        ? formatISO(editedContactFormData.birthDate, { representation: 'date' })
        : undefined,
      placeOfBirth: editedContactFormData.placeOfBirth || undefined,
      nationality: editedContactFormData.nationality || undefined,
      idNumber: editedContactFormData.idNumber || undefined,
      partnerFirstName: editedContactFormData._internals.contact?.migratedPartnerContact?.firstName || undefined,
      partnerLastName: editedContactFormData._internals.contact?.migratedPartnerContact?.lastName || undefined,
    },
    renterDetails: {
      householdType: editedContactFormData?.renterDetails?.householdType || undefined,
      professionType: editedContactFormData?.renterDetails?.professionType || undefined,
      petOwner: editedContactFormData?.renterDetails?.petOwner ?? undefined,
      incomeRange: editedContactFormData.renterDetails?.incomeRange ?? undefined,
    },
    phoneNumbers: editedContactFormData.phoneNumbers
      .map((phone) => ({
        ...phone,
        phoneType: phone.phoneType === '' ? 'UNDEFINED' : (phone.phoneType as PhoneNumberTypeEnum),
        preferred: phone.preferred,
      }))
      .filter(({ number }) => !!number),
    contactRelationships: contactRelationshipMapper(editedContactFormData),
    contactType: editedContactFormData._internals.contact?.isServiceContact
      ? editedContactFormData.contactType
      : undefined,
    preferredLanguage:
      editedContactFormData.preferredLanguage === ''
        ? undefined
        : (editedContactFormData.preferredLanguage as LanguageEnum),
    preferredTimes: editedContactFormData.preferredTimes as ContactTimeEnum[],
  };
}
