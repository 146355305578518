import {
  AssetSubTypeEnum,
  AssetVariationEnum,
  Contact,
  CountryEnum,
  IntentTypeEnum,
  Lead,
  LeadInfo,
  TimeFrameEnum,
} from 'api/graphql/generated/graphql';
import { useBFFPlacesDetailsQuery } from 'api/searchBff/hooks/usePlaceDetails';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import {
  assetConditionTranslation,
  assetSubTypeTranslation,
  assetTypeTranslation,
  assetVariationTranslation,
  contactTimeTranslation,
  intentTypeTranslation,
  propertyOccupationStatusTranslationKeys,
  timeFrameTranslationKeys,
} from 'const/enumTranslations';
import { rentTranslations } from 'const/rentTranslations';
import { getLegacyLocation } from 'util/buyerToRentSpecificationUtils';
import { getPreferredEmail } from 'util/contactUtils';
import { EMPTY_DATA_STRING, renderEmptyable, renderEmptyableArray, renderEmptyableString } from 'util/emptyDataUtils';
import { isSet } from 'util/filters';
import { translateCountryEnum } from 'util/i18n/translateCountryEnum';
import { TFunction, useTranslation } from 'util/i18next';
import { PlacesAddressSearchResult } from 'util/places/types';
import { fullName, joinWithComma } from 'util/stringUtils';
import { addressToSingleLineString } from 'util/summaries/addressSummary';
import { useFormatNumber } from 'util/useFormatNumber';

function translateTimeFrame(t: TFunction<['enums']>, timeFrame: string) {
  if (!timeFrame) {
    return '';
  }
  const translationKey = timeFrameTranslationKeys[timeFrame as TimeFrameEnum];
  return translationKey ? t(translationKey) : timeFrame;
}

export function useCreateShortLeadSummary(lead: Lead | LeadInfo | undefined) {
  const { t } = useTranslation(['lead', 'enums']);
  const { formatArea, formatPriceRange } = useFormatNumber();

  if (!lead) {
    return '';
  }

  const summary = [];
  const intentType = lead.intentType;
  if (intentType === 'SELL' || intentType === 'VALUATION' || intentType === 'UNDEFINED') {
    const sellerRentOutSpecification = lead.sellerRentOutSpecification;
    if (!sellerRentOutSpecification) return '';
    summary.push(sellerRentOutSpecification.address?.fullAddress ?? '');
    summary.push(
      renderEmptyable(
        sellerRentOutSpecification.type,
        (assetTypeEnum) => t(assetTypeTranslation[assetTypeEnum]) || assetTypeEnum,
      ),
    );
    summary.push(
      formatArea(sellerRentOutSpecification.livingArea, { unitSystem: sellerRentOutSpecification.unitSystem }),
    );
    summary.push(
      formatPriceRange(sellerRentOutSpecification.priceRangeFrom, sellerRentOutSpecification.priceRangeTo, {
        currency: sellerRentOutSpecification.priceRangeUnit,
      }),
    );
    summary.push(
      formatPriceRange(sellerRentOutSpecification.priceRangeFrom, sellerRentOutSpecification.priceRangeTo, {
        currency: sellerRentOutSpecification.priceRangeUnit,
      }),
    );
  } else {
    const buyerToRentSpecification = lead.buyerToRentSpecification;
    if (!buyerToRentSpecification) return '';
    summary.push(buyerToRentSpecification.city);
    summary.push(buyerToRentSpecification.country);
    summary.push(
      renderEmptyable(
        buyerToRentSpecification.type,
        (assetTypeEnum) => t(assetTypeTranslation[assetTypeEnum]) || assetTypeEnum,
      ),
    );

    //ToDo: this needs a different handling in the future
    summary.push(buyerToRentSpecification.area ? buyerToRentSpecification.area + 'm2' : '');
    summary.push(
      formatPriceRange(buyerToRentSpecification.priceMin, buyerToRentSpecification.priceMax, {
        currency: buyerToRentSpecification.currency,
      }),
    );
  }

  return summary.filter(Boolean).join(', ');
}

export const useCreateFullLeadSummary = (contact: Contact, lead?: Lead): string => {
  const { t } = useTranslation(['lead', 'enums']);
  const { formatAreaRange, formatPriceRange, formatRange, formatArea } = useFormatNumber();
  const { shopUnitSystem } = useActiveShop();

  const preferredAddress = contact?.addresses?.find((address) => address.preferred)?.address;
  const { shopRentUnit } = useActiveShop();

  const { details: placeDetails } = useBFFPlacesDetailsQuery(
    [
      preferredAddress?.placeId,
      lead?.sellerRentOutSpecification?.address?.placeId,
      ...(lead?.buyerToRentSpecification?.placeIds || []),
    ].filter(isSet),
  );

  const sellerRentOutSpecification = lead?.sellerRentOutSpecification;
  const emailAddress = contact ? getPreferredEmail(contact) : undefined;
  const phoneNumber = contact?.phoneNumbers?.find((phone) => phone.preferred)?.number;

  const formattedPreferredTimes = contact?.preferredTimes
    ?.filter((time) => time.preferredContactTime !== 'UNDEFINED')
    ?.map((time) => t(contactTimeTranslation[time.preferredContactTime]) || time.preferredContactTime)
    ?.join(', ');

  const getOwnerDetails = () => {
    if (!(['SELL', 'VALUATION', 'RENT_OUT', 'UNDEFINED'] as IntentTypeEnum[]).includes(lead?.intentType || 'UNDEFINED'))
      return '';

    return `
    -- ${t('lead:leadSummary.ownerDetails.title')} --
    ${
      sellerRentOutSpecification?.ownershipStatus === 'LH_CONTACT' ||
      sellerRentOutSpecification?.ownershipStatus === 'OWNER'
        ? `${t('lead:leadSummary.ownerDetails.owner')}: ${fullName(contact ?? {})}`
        : `${t('lead:leadSummary.ownerDetails.ownershipDescription')}: ${renderEmptyableString(
            sellerRentOutSpecification?.ownerDescription,
          )}`
    }
    ${t('lead:leadSummary.ownerDetails.occupationStatus')}: ${
      sellerRentOutSpecification?.occupationStatus
        ? t(propertyOccupationStatusTranslationKeys[sellerRentOutSpecification?.occupationStatus])
        : EMPTY_DATA_STRING
    }
    ${t('lead:leadSummary.ownerDetails.intention')}: ${
      lead?.intentType ? t(intentTypeTranslation[lead?.intentType]) || lead?.intentType : ''
    }
    ${t('lead:leadSummary.ownerDetails.timeFrame')}: ${renderEmptyable(lead?.timeFrame, (timeFrame) =>
      translateTimeFrame(t, timeFrame),
    )}
    `;
  };

  const getBuyerSearchAreaDetails = () => {
    const buyerToRentSpecification = lead?.buyerToRentSpecification;
    if (buyerToRentSpecification?.placeIds && buyerToRentSpecification?.placeIds.length) {
      return `${t('lead:leadSummary.searchDemand.area')}: ${renderEmptyableString(
        buyerToRentSpecification?.placeIds
          .map((placeId: string) => placeDetails?.find((place) => place.placeId === placeId)?.formattedAddress)
          .join(', '),
      )}`;
    }

    const legacyLocation = getLegacyLocation(buyerToRentSpecification);

    return `${t('lead:leadSummary.searchDemand.city')}: ${renderEmptyableString(legacyLocation?.city)}
      ${t('lead:leadSummary.searchDemand.country')}: ${renderEmptyableString(
      translateCountryEnum(t, legacyLocation?.country as CountryEnum),
    )}
      ${t('lead:leadSummary.searchDemand.area')}: ${renderEmptyableString(legacyLocation?.area)}
      ${t('lead:leadSummary.searchDemand.district')}: ${renderEmptyableString(legacyLocation?.district)}`;
  };

  const getLeadDetails = () => {
    if (
      (['SELL', 'VALUATION', 'UNDEFINED', 'RENT_OUT'] as IntentTypeEnum[]).includes(lead?.intentType || 'UNDEFINED')
    ) {
      const sellerRentOutUnitSystem = lead?.sellerRentOutSpecification?.unitSystem || shopUnitSystem;

      return `-- ${t('lead:leadSummary.propertyDetails.title')} --
      ${t('lead:leadSummary.propertyDetails.address')}: ${
        placeDetails?.find((place) => place.placeId === lead?.sellerRentOutSpecification?.address?.placeId)
          ?.formattedAddress || addressToSingleLineString(t)(lead?.sellerRentOutSpecification?.address ?? {})
      }
      ${t('lead:leadSummary.propertyDetails.type')}: ${renderEmptyable(
        lead?.sellerRentOutSpecification?.type,
        (assetTypeEnum) => t(assetTypeTranslation[assetTypeEnum]) || assetTypeEnum,
      )}
      ${t('lead:leadSummary.propertyDetails.subType')}: ${renderEmptyable(
        lead?.sellerRentOutSpecification?.subType,
        (assetSubTypeEnum) => t(assetSubTypeTranslation[assetSubTypeEnum]) || assetSubTypeEnum,
      )}
      ${t('lead:leadSummary.propertyDetails.variation')}: ${renderEmptyable(
        lead?.sellerRentOutSpecification?.variation,
        (assetVariationEnum) => t(assetVariationTranslation[assetVariationEnum]) || assetVariationEnum,
      )}
      ${t('lead:leadSummary.propertyDetails.livingArea')}: ${formatArea(lead?.sellerRentOutSpecification?.livingArea, {
        unitSystem: sellerRentOutUnitSystem,
      })}
      ${t('lead:leadSummary.propertyDetails.constructionDate')}: ${renderEmptyable(
        lead?.sellerRentOutSpecification?.constructionYear,
        (year) => year.toString(),
      )}
      ${t('lead:leadSummary.propertyDetails.plotSurface')}: ${formatArea(lead?.sellerRentOutSpecification?.plotArea, {
        unitSystem: sellerRentOutUnitSystem,
      })} 
      ${t('lead:leadSummary.propertyDetails.features')}: ${renderEmptyableArray(
        [
          lead?.sellerRentOutSpecification?.balcony && t('lead:leadSummary.propertyDetails.features.balcony'),
          lead?.sellerRentOutSpecification?.garden && t('lead:leadSummary.propertyDetails.features.garden'),
          lead?.sellerRentOutSpecification?.parking && t('lead:leadSummary.propertyDetails.features.parking'),
          lead?.sellerRentOutSpecification?.swimmingPool && t('lead:leadSummary.propertyDetails.features.swimmingPool'),
          lead?.sellerRentOutSpecification?.guestToilet && t('lead:leadSummary.propertyDetails.features.guestToilet'),
          lead?.sellerRentOutSpecification?.elevator && t('lead:leadSummary.propertyDetails.features.elevator'),
          lead?.sellerRentOutSpecification?.garage && t('lead:leadSummary.propertyDetails.features.garage'),
        ].filter((feature) => feature),
        (feature) => feature || '',
      )}
      ${t('lead:leadSummary.propertyDetails.numberOfRooms')}: ${renderEmptyable(
        lead?.sellerRentOutSpecification?.numberOfRooms,
        (numberOfRooms) => numberOfRooms.toString(),
      )}
      ${t('lead:leadSummary.propertyDetails.numberOfBathrooms')}: ${renderEmptyable(
        lead?.sellerRentOutSpecification?.numberOfBathrooms,
        (numberOfBathrooms) => numberOfBathrooms.toString(),
      )}
      ${t('lead:leadSummary.propertyDetails.floor')}: ${renderEmptyable(
        lead?.sellerRentOutSpecification?.floor,
        (floor) => floor.toString(),
      )}
      ${t('lead:leadSummary.propertyDetails.numberOfFloors')}: ${renderEmptyable(
        lead?.sellerRentOutSpecification?.totalFloors,
        (totalFloors) => totalFloors.toString(),
      )}
      ${t('lead:leadSummary.propertyDetails.quality')}: ${renderEmptyable(
        lead?.sellerRentOutSpecification?.condition,
        (assetConditionEnum) => t(assetConditionTranslation[assetConditionEnum]) || assetConditionEnum,
      )}
      ${
        lead?.intentType === 'RENT_OUT'
          ? `${t(rentTranslations[shopRentUnit].generic)}: ${formatPriceRange(
              lead?.sellerRentOutSpecification?.rentRangeFrom,
              lead?.sellerRentOutSpecification?.rentRangeTo,
              { currency: lead?.sellerRentOutSpecification?.rentPriceUnit },
            )}`
          : `${t('lead:leadSummary.propertyDetails.priceRange')}: ${formatPriceRange(
              lead?.sellerRentOutSpecification?.priceRangeFrom,
              lead?.sellerRentOutSpecification?.priceRangeTo,
              { currency: lead?.sellerRentOutSpecification?.priceRangeUnit },
            )}`
      }`;
    }

    if (lead?.go3PropertyId) {
      return `${t('lead:leadSummary.propertyDetails.go3PropertyId')}: ${lead?.go3PropertyId}`;
    }

    const buyerToRentSpecification = lead?.buyerToRentSpecification;
    const buyerToRentUnitSystem = buyerToRentSpecification?.unitSystem || shopUnitSystem;

    return `-- ${t('lead:leadSummary.searchDemand.title')} --
      ${getBuyerSearchAreaDetails()}
      ${t('lead:leadSummary.searchDemand.bedrooms')}: ${formatRange(
      buyerToRentSpecification?.bedroomsMin,
      buyerToRentSpecification?.bedroomsMax,
    )}
      ${t('lead:leadSummary.searchDemand.livingArea')}: ${formatAreaRange(
      buyerToRentSpecification?.livingAreaMin,
      buyerToRentSpecification?.livingAreaMax,
      { unitSystem: buyerToRentUnitSystem },
    )}
      ${t('lead:leadSummary.searchDemand.plotSurface')}: ${formatAreaRange(
      buyerToRentSpecification?.plotSurfaceMin,
      buyerToRentSpecification?.plotSurfaceMax,
      { unitSystem: buyerToRentUnitSystem },
    )}
      ${t('lead:leadSummary.searchDemand.rooms')}: ${formatRange(
      buyerToRentSpecification?.roomsMin,
      buyerToRentSpecification?.roomsMax,
    )}
      ${t('lead:leadSummary.searchDemand.totalSurface')}: ${formatAreaRange(
      buyerToRentSpecification?.totalSurfaceMin,
      buyerToRentSpecification?.totalSurfaceMax,
      { unitSystem: buyerToRentUnitSystem },
    )}
      ${t('lead:leadSummary.searchDemand.subType')}: ${renderEmptyableArray(
      buyerToRentSpecification?.subTypes,
      (assetSubTypeEnum) => t(assetSubTypeTranslation[assetSubTypeEnum as AssetSubTypeEnum]) || assetSubTypeEnum,
    )}
      ${t('lead:leadSummary.searchDemand.variation')}: ${renderEmptyableArray(
      buyerToRentSpecification?.variations,
      (assetVariationEnum) =>
        t(assetVariationTranslation[assetVariationEnum as AssetVariationEnum]) || assetVariationEnum,
    )}
      ${t('lead:leadSummary.searchDemand.type')}: ${renderEmptyable(
      buyerToRentSpecification?.type,
      (assetTypeEnum) => t(assetTypeTranslation[assetTypeEnum]) || assetTypeEnum,
    )}
      ${
        lead?.intentType === 'BUY'
          ? `${t('lead:leadSummary.searchDemand.priceRange')}: ${formatPriceRange(
              buyerToRentSpecification?.priceMin,
              buyerToRentSpecification?.priceMax,
              { currency: buyerToRentSpecification?.currency },
            )}`
          : `${t(rentTranslations[shopRentUnit].net)}: ${formatPriceRange(
              buyerToRentSpecification?.monthlyNetRentMin,
              buyerToRentSpecification?.monthlyNetRentMax,
              { currency: buyerToRentSpecification?.currency },
            )})}
            ${t(rentTranslations[shopRentUnit].total)}: ${formatPriceRange(
              buyerToRentSpecification?.monthlyTotalRentMin,
              buyerToRentSpecification?.monthlyTotalRentMax,
              { currency: buyerToRentSpecification?.currency },
            )}`
      }
      ${t('lead:leadSummary.searchDemand.personalNote')}: ${renderEmptyableString(lead?.personalNote)}`;
  };

  const leadString =
    //
    `-- ${t('lead:leadSummary.contactDetails.title')} --
    ${t('lead:leadSummary.contactDetails.firstName')}: ${contact?.firstName || ''}
    ${t('lead:leadSummary.contactDetails.lastName')}: ${contact?.lastName || ''}
    ${t('lead:leadSummary.contactDetails.email')}: ${emailAddress}
    ${t('lead:leadSummary.contactDetails.phone')}: ${renderEmptyableString(phoneNumber)}
    ${t('lead:leadSummary.contactDetails.address')}: ${
      placeDetails?.find((place) => place.placeId === preferredAddress?.placeId)?.formattedAddress || EMPTY_DATA_STRING
    }
    ${t('lead:leadSummary.contactDetails.prefTime')}: ${renderEmptyableString(formattedPreferredTimes)}
    ${t('lead:leadSummary.contactDetails.personalNote')}: ${renderEmptyableString(contact?.personalNote)}

    ${t('lead:leadSummary.contactDetails.customerMessage')}: ${renderEmptyableString(lead?.customerMessage)}
    ${getOwnerDetails()}
    ${getLeadDetails()}`;

  return leadString
    .split('\n')
    .map((line) => line.trim())
    .join('\r\n');
};

export function addIfValueExists(key: string, value: string | null | undefined) {
  return value && value !== EMPTY_DATA_STRING ? `${key}: ${value}` : null;
}

export function useSearchDemandReferralNote() {
  const { t } = useTranslation(['lead', 'enums']);
  const { formatAreaRange, formatPriceRange, formatRange } = useFormatNumber();
  const { shopRentUnit } = useActiveShop();

  return (lead?: Lead, places?: PlacesAddressSearchResult[]) => {
    const buyerToRentSpecification = lead?.buyerToRentSpecification;
    return joinWithComma([
      addIfValueExists(
        t('lead:leadSummary.searchDemand.bedrooms'),
        formatRange(buyerToRentSpecification?.bedroomsMin, buyerToRentSpecification?.bedroomsMax),
      ),
      /** TODO: Remove else case if we have placeId for everything */
      ...((places && places.length) || buyerToRentSpecification?.customSearchDemandPolygons?.length
        ? [
            addIfValueExists(
              t('lead:leadSummary.searchDemand.searchArea'),
              joinWithComma([
                ...(places?.map((place) => place.formattedAddress) || []),
                ...(buyerToRentSpecification?.customSearchDemandPolygons?.map((polygon) => polygon?.name) || []),
              ]),
            ),
          ]
        : [
            addIfValueExists(t('lead:leadSummary.searchDemand.city'), buyerToRentSpecification?.city),
            addIfValueExists(
              t('lead:leadSummary.searchDemand.country'),
              translateCountryEnum(t, buyerToRentSpecification?.country as CountryEnum),
            ),
            addIfValueExists(t('lead:leadSummary.searchDemand.district'), buyerToRentSpecification?.district),
          ]),
      /** Remove until this part */
      addIfValueExists(
        t('lead:leadSummary.searchDemand.livingArea'),
        buyerToRentSpecification &&
          formatAreaRange(buyerToRentSpecification.livingAreaMin, buyerToRentSpecification.livingAreaMax, {
            unitSystem: buyerToRentSpecification.unitSystem,
          }),
      ),
      addIfValueExists(
        t('lead:leadSummary.searchDemand.rooms'),
        formatRange(buyerToRentSpecification?.roomsMin, buyerToRentSpecification?.roomsMax),
      ),
      addIfValueExists(
        t('lead:leadSummary.searchDemand.subType'),
        renderEmptyableArray(
          buyerToRentSpecification?.subTypes,
          (assetSubTypeEnum) => t(assetSubTypeTranslation[assetSubTypeEnum as AssetSubTypeEnum]) || assetSubTypeEnum,
        ),
      ),
      addIfValueExists(
        t('lead:leadSummary.searchDemand.variation'),
        renderEmptyableArray(
          buyerToRentSpecification?.variations,
          (assetVariationEnum) =>
            t(assetVariationTranslation[assetVariationEnum as AssetVariationEnum]) || assetVariationEnum,
        ),
      ),
      addIfValueExists(
        t('lead:leadSummary.searchDemand.type'),
        renderEmptyable(
          buyerToRentSpecification?.type,
          (assetTypeEnum) => t(assetTypeTranslation[assetTypeEnum]) || assetTypeEnum,
        ),
      ),
      ...(lead?.intentType === 'BUY'
        ? [
            addIfValueExists(
              t('lead:leadSummary.searchDemand.priceRange'),
              formatPriceRange(buyerToRentSpecification?.priceMin, buyerToRentSpecification?.priceMax, {
                currency: buyerToRentSpecification?.currency,
              }),
            ),
          ]
        : [
            addIfValueExists(
              t(rentTranslations[shopRentUnit].net),
              formatPriceRange(
                buyerToRentSpecification?.monthlyNetRentMin,
                buyerToRentSpecification?.monthlyNetRentMax,
                {
                  currency: buyerToRentSpecification?.currency,
                },
              ),
            ),
            addIfValueExists(
              t(rentTranslations[shopRentUnit].total),
              formatPriceRange(
                buyerToRentSpecification?.monthlyTotalRentMin,
                buyerToRentSpecification?.monthlyTotalRentMax,
                { currency: buyerToRentSpecification?.currency },
              ),
            ),
          ]),
    ]);
  };
}
