import { CurrencyEnum, UnitSystemEnum } from 'api/graphql/generated/graphql';
import { PropertyDetailsFormData } from 'util/schemas/propertyDetailsSchema';

export function newPropertyDefaultValues(
  shopCurrency: CurrencyEnum,
  shopUnitSystem: UnitSystemEnum,
): PropertyDetailsFormData & {
  teamId: string | undefined;
} {
  return {
    _internals: {
      contactIntentType: '',
      isKnownProperty: true,
      changeToShopCurrency: false,
    },
    teamId: '',
    intentType: 'SELL',
    knownProperty: {
      linkedPropertyArray: [],
      personalNote: undefined,
    },
    leadSource: 'UNDEFINED',
    property: {
      _internals: {
        contactAddress: undefined,
      },
      additionalNoteProperty: undefined,
      condition: undefined,
      constructionDate: undefined,
      isClientAddress: false,
      occupationStatus: undefined,
      livingArea: undefined,
      owner: undefined,
      ownershipStatus: undefined,
      ownershipDescription: undefined,
      plotSurface: undefined,
      priceRangeFrom: undefined,
      priceRangeTo: undefined,
      priceRangeUnit: shopCurrency,
      rentRangeFrom: undefined,
      rentRangeTo: undefined,
      rentRangeUnit: shopCurrency,
      _initial: {
        priceRangeUnit: shopCurrency,
        rentRangeUnit: shopCurrency,
      },
      unitSystem: shopUnitSystem,
      propertySubtype: undefined,
      isAddProperty: false,
      propertyVariation: undefined,
      propertyType: undefined,
      relationship: undefined,
      timeFrame: undefined,
      propertyAddress: undefined,
    },
    search: {
      area: undefined,
      bedroomsMax: undefined,
      bedroomsMin: undefined,
      city: undefined,
      countryCode: undefined,
      district: undefined,
      livingAreaMax: undefined,
      livingAreaMin: undefined,
      monthlyNetRentMax: undefined,
      monthlyNetRentMin: undefined,
      monthlyTotalRentMax: undefined,
      monthlyTotalRentMin: undefined,
      personalNote: undefined,
      plotSurfaceMax: undefined,
      plotSurfaceMin: undefined,
      currency: shopCurrency,
      _initial: {
        currency: shopCurrency,
      },
      unitSystem: shopUnitSystem,
      priceMax: undefined,
      priceMin: undefined,
      roomsMax: undefined,
      roomsMin: undefined,
      subTypes: [],
      variations: [],
      totalSurfaceMax: undefined,
      totalSurfaceMin: undefined,
      propertyType: undefined,
      geojson: {
        type: 'FeatureCollection',
        features: [],
      },
      placeIds: [{ placeId: '' }],
    },
  };
}
