import { CurrencyEnum, LeadSourceEnum, UnitSystemEnum } from 'api/graphql/generated/graphql';
import { Property } from 'api/rest/models/PropertyTypeMapping';
import { FeatureCollection, GeoJsonProperties, Polygon } from 'geojson';
import { useMemo } from 'react';
import { ListingWithLegacyFields } from 'util/go3';
import { TFunction, useTranslation } from 'util/i18next';
import { useAddressSchema } from 'util/schemas/addressSchema';
import { useSchemaPrimitives } from 'util/schemas/schemaPrimitives';
import { z } from 'zod';

function priceChangeRequiredIssue(t: TFunction<['lead']>, field: string) {
  return {
    code: z.ZodIssueCode.custom,
    path: [field],
    message: t('lead:currency.priceChangeRequired'),
  };
}

function usePropertySchema() {
  const { optionalNumber, optionalString, requiredString, optionalBoolean } = useSchemaPrimitives();
  const { t } = useTranslation(['lead']);
  const addressSchema = useAddressSchema();

  return useMemo(() => {
    return z
      .object({
        _internals: z.object({
          contactAddress: z.object({}).optional(),
          latitude: optionalNumber,
          longitude: optionalNumber,
        }),
        additionalNoteProperty: optionalString,
        cityProperty: optionalString,
        condition: optionalString,
        constructionDate: optionalNumber,
        coowner: optionalString,
        countryProperty: optionalString,
        isClientAddress: optionalBoolean,
        occupationStatus: z.custom<Property['occupationStatus']>().optional(),
        livingArea: optionalNumber,
        ownershipStatus: z.custom<Property['ownershipStatus']>().optional(),
        ownershipDescription: optionalString,
        owner: z
          .object({
            id: requiredString,
            firstName: optionalString,
            lastName: optionalString,
          })
          .optional(),
        plotSurface: optionalNumber,
        rooms: optionalNumber,
        bathrooms: optionalNumber,
        floor: optionalNumber,
        totalFloors: optionalNumber,
        postalCodeProperty: optionalString,
        priceRangeFrom: optionalNumber,
        priceRangeTo: optionalNumber,
        priceRangeUnit: z.custom<CurrencyEnum>(),
        rentRangeFrom: optionalNumber,
        rentRangeTo: optionalNumber,
        rentRangeUnit: z.custom<CurrencyEnum>(),
        _initial: z.object({
          priceRangeFrom: optionalNumber,
          priceRangeTo: optionalNumber,
          priceRangeUnit: z.custom<CurrencyEnum>(),
          rentRangeFrom: optionalNumber,
          rentRangeTo: optionalNumber,
          rentRangeUnit: z.custom<CurrencyEnum>(),
        }),
        unitSystem: z.custom<UnitSystemEnum>(),
        propertyType: z
          .custom<Property['assetType']>()
          .optional()
          .refine((propertyType) => !!propertyType, t('lead:formValidation.propertyTypeRequired')),
        propertySubtype: z.custom<Property['assetSubType']>().optional(),
        isAddProperty: optionalBoolean,
        propertyVariation: z.custom<Property['assetVariation']>().optional(),
        relationship: optionalString,
        streetNameProperty: optionalString,
        streetNumberProperty: optionalString,
        propertyAddress: addressSchema.nullable().optional(),
        timeFrame: optionalString,
        balcony: optionalBoolean,
        garden: optionalBoolean,
        parking: optionalBoolean,
        swimmingPool: optionalBoolean,
        guestToilet: optionalBoolean,
        elevator: optionalBoolean,
        garage: optionalBoolean,
        placeId: optionalString,
        cdfs: z.string().array().optional(),
      })
      .superRefine((val, ctx) => {
        if (val.priceRangeUnit && val.priceRangeUnit !== val._initial.priceRangeUnit) {
          (['priceRangeFrom', 'priceRangeTo'] as const).forEach((field) => {
            if (val[field] === val._initial[field]) {
              ctx.addIssue(priceChangeRequiredIssue(t, field));
            }
          });
        }
        if (val.rentRangeUnit && val.rentRangeUnit !== val._initial.rentRangeUnit) {
          (['rentRangeFrom', 'rentRangeTo'] as const).forEach((field) => {
            if (val[field] === val._initial[field]) {
              ctx.addIssue(priceChangeRequiredIssue(t, field));
            }
          });
        }
      });
  }, [addressSchema, optionalString, optionalNumber, optionalBoolean, requiredString, t]);
}

export type PropertyFormData = z.infer<ReturnType<typeof usePropertySchema>>;

function usePropertySearchSchema() {
  const { optionalNumber, optionalString } = useSchemaPrimitives();
  const { t } = useTranslation(['lead']);

  return useMemo(() => {
    return z
      .object({
        placeId: optionalString,
        area: optionalString,
        bedroomsMax: optionalNumber,
        bedroomsMin: optionalNumber,
        city: optionalString,
        countryCode: optionalString,
        district: optionalString,
        livingAreaMax: optionalNumber,
        livingAreaMin: optionalNumber,
        monthlyNetRentMax: optionalNumber,
        monthlyNetRentMin: optionalNumber,
        monthlyTotalRentMax: optionalNumber,
        monthlyTotalRentMin: optionalNumber,
        priceMax: optionalNumber,
        priceMin: optionalNumber,
        currency: z.custom<CurrencyEnum>(),
        unitSystem: z.custom<UnitSystemEnum>(),
        _initial: z.object({
          monthlyNetRentMax: optionalNumber,
          monthlyNetRentMin: optionalNumber,
          monthlyTotalRentMax: optionalNumber,
          monthlyTotalRentMin: optionalNumber,
          priceMax: optionalNumber,
          priceMin: optionalNumber,
          currency: z.custom<CurrencyEnum>(),
        }),
        personalNote: optionalString,
        plotSurfaceMax: optionalNumber,
        plotSurfaceMin: optionalNumber,

        roomsMax: optionalNumber,
        roomsMin: optionalNumber,
        subTypes: z.custom<Property['assetSubType'][]>().optional(),
        variations: z.custom<Property['assetVariation'][]>().optional(),
        totalSurfaceMax: optionalNumber,
        totalSurfaceMin: optionalNumber,
        propertyType: z
          .custom<Property['assetType']>()
          .optional()
          .refine((propertyType) => !!propertyType, t('lead:formValidation.propertyTypeRequired')),
        geojson: z.custom<FeatureCollection<Polygon, GeoJsonProperties>>().optional(),
        placeIds: z.preprocess(
          (val) => {
            if (!Array.isArray(val)) {
              return undefined;
            }
            return val.filter((placeIdObject) => {
              if (placeIdObject?.placeId !== '') {
                return !!placeIdObject?.placeId?.trim();
              }
            });
          },
          z
            .array(
              z
                .object({
                  placeId: z.string(),
                })
                .passthrough(),
            )
            .optional(),
        ),
        cdfs: z.string().array().optional(),
      })
      .superRefine((val, ctx) => {
        if (val.currency && val.currency !== val._initial.currency) {
          (
            [
              'monthlyNetRentMin',
              'monthlyNetRentMax',
              'monthlyTotalRentMin',
              'monthlyTotalRentMax',
              'priceMax',
              'priceMin',
            ] as const
          ).forEach((field) => {
            if (val[field] && val[field] === val._initial[field]) {
              ctx.addIssue(priceChangeRequiredIssue(t, field));
            }
          });
        }
      });
  }, [optionalNumber, optionalString, t]);
}

export type SearchFormData = z.infer<ReturnType<typeof usePropertySearchSchema>>;

function useKnownPropertySchema() {
  const { optionalString } = useSchemaPrimitives();
  return useMemo(() => {
    return z.object({
      linkedPropertyArray: z.custom<ListingWithLegacyFields>().array().min(1),
      personalNote: optionalString,
    });
  }, [optionalString]);
}

function useLeadSourceSchema() {
  const { t } = useTranslation(['lead']);
  const { requiredString } = useSchemaPrimitives();
  return requiredString.refine((leadSource) => leadSource !== 'UNDEFINED', {
    message: t('lead:formValidation.leadSourceRequired'),
  });
}

// Only needed for type inference
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function useJoinedSchema() {
  const { requiredString, optionalString, optionalBoolean } = useSchemaPrimitives();
  const searchSchema = usePropertySearchSchema();
  const propertySchema = usePropertySchema();
  const knownPropertySchema = useKnownPropertySchema();

  return z.object({
    _internals: z.object({
      contactIntentType: requiredString,
      isKnownProperty: z.boolean(),
      leadShopId: optionalString,
      isEditMode: optionalBoolean.nullable(),
      changeToShopCurrency: z.boolean(),
    }),
    intentType: z.enum(['VALUATION', 'UNDEFINED', 'SELL', 'RENT_OUT', 'TO_RENT', 'BUY']),
    leadSource: z.custom<LeadSourceEnum>(),
    property: propertySchema,
    search: searchSchema,
    knownProperty: knownPropertySchema,
  });
}

export type PropertyDetailsFormData = z.infer<ReturnType<typeof useJoinedSchema>>;

export function usePropertyDetailsSchema() {
  const { t } = useTranslation(['lead']);
  const searchSchema = usePropertySearchSchema();
  const propertySchema = usePropertySchema();
  const knownPropertySchema = useKnownPropertySchema();
  const leadSourceSchema = useLeadSourceSchema();
  const { optionalString } = useSchemaPrimitives();

  return useMemo(() => {
    const sellerSchema = z.object({
      _internals: z.object({
        contactIntentType: z.string().min(1, { message: t('lead:formValidation.intentRequired') }),
        leadShopId: optionalString,
        isKnownProperty: z.boolean(),
      }),
      intentType: z.enum(['VALUATION', 'UNDEFINED', 'SELL']),
      leadSource: leadSourceSchema,
      property: propertySchema,
    });

    const rentOutSchema = z.object({
      _internals: z.object({
        contactIntentType: z.string().min(1, { message: t('lead:formValidation.intentRequired') }),
        leadShopId: optionalString,
        isKnownProperty: z.boolean(),
      }),
      intentType: z.literal('RENT_OUT'),
      leadSource: leadSourceSchema,
      property: propertySchema,
    });

    const buyerRentalKnownPropertySchema = z.object({
      _internals: z.object({
        contactIntentType: z.string().min(1, { message: t('lead:formValidation.intentRequired') }),
        isKnownProperty: z.literal(true),
        leadShopId: optionalString,
      }),
      intentType: z.enum(['TO_RENT', 'BUY']),
      leadSource: leadSourceSchema,
      knownProperty: knownPropertySchema,
    });

    const buyerRentalSearchSchema = z.object({
      _internals: z.object({
        leadShopId: optionalString,
        contactIntentType: z.string().min(1, { message: t('lead:formValidation.intentRequired') }),
        isKnownProperty: z.literal(false),
      }),
      search: searchSchema,
      intentType: z.enum(['TO_RENT', 'BUY']),
      leadSource: leadSourceSchema,
    });
    return buyerRentalSearchSchema.or(
      z.discriminatedUnion('intentType', [sellerSchema, rentOutSchema, buyerRentalKnownPropertySchema]),
    );
  }, [t, leadSourceSchema, propertySchema, knownPropertySchema, searchSchema, optionalString]);
}
