import { FormHelperText, SxProps } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { PropertyEngineListingStatus } from 'api/graphql/generated/graphql';
import { useListingSearch } from 'api/graphql/hooks/useListingSearch';
import { PropertyTooltipChip } from 'components/general/Chips/TooltipChip/PropertyTooltipChip';
import { PropertyItem } from 'components/property/PropertySearch/PropertyItem';
import { useActiveShopId } from 'components/state/ActiveShopProvider';
import { ReactNode, SyntheticEvent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ExposeProperty } from 'util/email';
import { ListingWithLegacyFields } from 'util/go3';
import { useTranslation } from 'util/i18next';
import { mergeSx } from 'util/styleUtils';
import { useSearchText } from 'util/useDebounce';
import { useShowError } from 'util/useShowError';

const getOptionsToSearch = (option: ListingWithLegacyFields) => {
  let matchString = '';
  if (option?.address?.city) {
    matchString += option.address.city;
  }
  if (option?.address?.streetName) {
    matchString += option.address.streetName;
  }
  if (option?.utag) {
    matchString += option.utag;
  }
  if (option?.fileAs) {
    matchString += option.fileAs;
  }
  return matchString;
};
type PropertyOption = ListingWithLegacyFields | ExposeProperty;
function getProperty(option: PropertyOption) {
  return 'property' in option ? option.property : option;
}
export const PropertySearchField = ({
  allowDevelopmentProperties = false,
  disabled,
  hideArrow = false,
  label,
  maxProperties,
  onlyActiveProperties = false,
  isEmail = false,
  isExposeProperty = false,
  required,
  disablePortal,
  sx,
}: {
  allowDevelopmentProperties?: boolean;
  disabled?: boolean;
  hideArrow?: boolean;
  label?: string;
  maxProperties?: number;
  onlyActiveProperties?: boolean;
  isEmail?: boolean;
  isExposeProperty?: boolean;
  required?: boolean;
  disablePortal?: boolean;
  sx?: SxProps;
}) => {
  const { activeShopId } = useActiveShopId();
  const { t } = useTranslation(['enums', 'communication', 'errors']);

  const { searchText, rawSearchText, setSearchText } = useSearchText();
  const { watch, control } = useFormContext();
  const formValueProperties = watch('properties');

  const activeOnlyOptions = ['ACTIVE', 'OFFER_SHOW'] as PropertyEngineListingStatus[];

  const allEmailOptions = [
    'ACTIVE',
    'PENDING',
    'OFFER_SHOW',
    'OFFER_NO_SHOW',
    'STREET_INDEX',
    'CLOSED_BY_EV',
    'CLOSED_BY_OTHERS',
  ] as PropertyEngineListingStatus[];

  const emailOptions = onlyActiveProperties ? activeOnlyOptions : allEmailOptions;
  const listingStatusOptions = isEmail ? emailOptions : undefined;

  const { listings, isLoading, error } = useListingSearch(
    {
      shopId: activeShopId ?? '',
      query: searchText,
      listingStatus: listingStatusOptions,
      listingTypes: allowDevelopmentProperties ? ['DEVELOPMENT', 'RENTAL', 'SALE'] : ['RENTAL', 'SALE'],
      perPage: 10,
    },
    { enabled: !!searchText },
  );

  useShowError(t('communication:emailForm.propertySearch.error'), !!error);

  const onSearchStringChanged = (_: SyntheticEvent<Element, Event>, value: string) => {
    setSearchText(value);
  };

  const isDisabled = !!maxProperties && formValueProperties?.length >= maxProperties;
  const maxPropertiesLabelSuffix = maxProperties
    ? ` ${t('communication:emailForm.properties.max.value', { value: maxProperties })}`
    : '';
  const newListings = isExposeProperty
    ? listings?.map((property) => ({
        comment: '',
        property,
      }))
    : listings;

  return (
    <Box sx={disablePortal ? mergeSx({ position: 'relative' }, sx) : sx}>
      <Controller
        control={control}
        name="properties"
        rules={{ required: true }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Autocomplete
            open={!!rawSearchText.length}
            noOptionsText={t('communication:propertySearch.noMatches')}
            loading={isLoading}
            loadingText={t('communication:emailForm.properties.loading')}
            disableClearable
            filterOptions={(options) => options}
            PaperComponent={CustomPaper}
            multiple
            limitTags={3}
            value={value}
            disablePortal={disablePortal}
            disabled={disabled}
            options={newListings || []}
            getOptionLabel={(option) => {
              return typeof option === 'string' ? option : getOptionsToSearch(getProperty(option));
            }}
            onChange={(_, items) => {
              onChange(items);
            }}
            isOptionEqualToValue={(option: string | PropertyOption, value: PropertyOption) => {
              if (!option || !value) {
                return false;
              }

              return typeof option === 'string'
                ? option === getProperty(value).utag
                : getProperty(option).utag === getProperty(value).utag;
            }}
            onInputChange={(event, newInputValue) => onSearchStringChanged(event, newInputValue)}
            id="properties"
            renderOption={(props, property: PropertyOption) => (
              <MenuItem {...props}>
                <PropertyItem property={getProperty(property)} />
              </MenuItem>
            )}
            freeSolo={false}
            getOptionDisabled={() => isDisabled}
            filterSelectedOptions
            renderTags={(value: PropertyOption[], getTagProps) =>
              value.map((option, index: number) => (
                <PropertyTooltipChip
                  key={getProperty(option).utag}
                  propertyId={getProperty(option).utag || ''}
                  chipProps={{ ...getTagProps({ index }), interactive: true }}
                />
              ))
            }
            renderInput={(params) => (
              <>
                <TextField
                  variant="outlined"
                  {...params}
                  label={label ?? `${t('communication:emailForm.properties.label')}${maxPropertiesLabelSuffix}`}
                  required={required}
                  error={!!error}
                  InputProps={{
                    sx: {
                      gap: 1,
                    },
                    ...params.InputProps,
                    endAdornment: hideArrow ? null : params.InputProps.endAdornment,
                  }}
                />
                <Stack direction="row">
                  <FormHelperText>{error?.message}</FormHelperText>
                </Stack>
              </>
            )}
          />
        )}
      />
    </Box>
  );
};

function CustomPaper(props: { className?: string; children?: ReactNode }) {
  return (
    <Paper
      sx={{
        '& .MuiAutocomplete-listbox': {
          '& .MuiAutocomplete-option': {
            display: 'block',
          },
        },
      }}
      {...props}
    />
  );
}
