import { CurrencyEnum, UnitSystemEnum } from 'api/graphql/generated/graphql';
import { ContactDetailsFormData } from 'util/schemas/contactDetailsSchema';
import { PropertyDetailsFormData } from 'util/schemas/propertyDetailsSchema';
import { newContactDefaultValues } from './newContactDefaultValues';
import { newPropertyDefaultValues } from './newPropertyDefaultValues';

export function newLeadDefaultValues(
  shopCurrency: CurrencyEnum,
  unitSystem: UnitSystemEnum,
): ContactDetailsFormData & PropertyDetailsFormData {
  const contactDefaultValues = newContactDefaultValues(shopCurrency);
  const propertyDefaultValues = newPropertyDefaultValues(shopCurrency, unitSystem);

  return {
    ...contactDefaultValues,
    ...propertyDefaultValues,
    _internals: {
      ...contactDefaultValues._internals,
      ...propertyDefaultValues._internals,
    },
  };
}
